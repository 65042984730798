import { useState, useCallback, useEffect } from 'react';

// Libs
import createGraphQLRequest from '../library/create-graphql-request';

const useMutation = (query, options = {}) => {
  const [ isSending, setIsSending ] = useState(false);
  const [ error, setError ] = useState(undefined);
  const [ response, setResponse ] = useState(undefined);
  const wasFulfilled = error || response;

  useEffect(() => {
    // TODO: Cancel subscriptions
    setIsSending(false);
    setError(undefined);
    setResponse(undefined);
  }, [ query ]);

  const sendMutation = useCallback((variables = {}) => {
    var handleSuccess = (data) => {
      setResponse(data);
      setIsSending(false);
    };

    var handleError = (err) => {
      setError(err);
      setIsSending(false);
    };

    try {
      setIsSending(true);
      setError(undefined);
      setResponse(undefined);

      createGraphQLRequest(query, variables, options)
        .then(handleSuccess)
        .catch(handleError);
    } catch (e) {
      handleError(e);
    }
  }, [ query, options ]);

  return {
    isSending,
    isFetching: isSending,
    error,
    response,
    wasFulfilled,
    sendMutation
  }
}

/*
useMutation(``);

useMutation.sendMutation();

const { sendQuery } = useQuery(``, {
  parseResponse: (response) => {

  },
  parseRequest: (request) => {

  }
});

sendQuery({
  id: 'aaaa'
});
*/

export default useMutation;