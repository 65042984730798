import React, { useContext, useEffect } from 'react';
import TitleContext from '../../../../contexts/Title';

const PageTitle = ({ title }) => {
  const titleContext = useContext(TitleContext);

  useEffect(() => {
    titleContext.setTitle(title);
  }, [ title ]);

  return null;
}

export default PageTitle;