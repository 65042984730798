import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Components
import Layout from '../../components/DefaultLayout';
import { Container, Title, Body } from '../../components/Layout/Page';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api.esl.teewe.com.br';

const useFetchDashboardStatus = () => {
  const [ error, setError ] = useState(undefined);
  const [ loading, setLoading ] = useState(false);
  const [ version, setVersion ] = useState(null);
  const [ name, setName ] = useState(null);

  useEffect(() => {
    setLoading(true);

    axios.get('/manifest.json')
      .then((response) => {
        setName(response.data.name);
        setVersion(response.data.version);
        setLoading(false);
        setError(null);
      })
      .catch((err) => {
        setName(null)
        setVersion(null);
        setLoading(false);
        setError(err.message || err.toString());
      });
  }, [ ]);

  return { version, name, error, loading };
}

const useFetchApiStatus = (endpoint) => {
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(undefined);
  const [ version, setVersion ] = useState(null);
  const [ status, setStatus ] = useState(null);

  useEffect(() => {
    setLoading(true);

    axios.get(`${endpoint}/status`)
      .then((response) => {        
        setStatus(response.data.status);
        setVersion(response.data.version);
        setLoading(false);
        setError(undefined);
      })
      .catch((err) => {
        setStatus(null);
        setVersion(null);

        if (err.response) {
          setError(err.response?.data?.status || 'Offline');
        } else {
          setError(`Request error: ${err.message}`);
        }

        setLoading(false);
      });
  }, [ endpoint ]);

  return { version, status, error, loading };
}

const StatusInfo = ({ error, loading }) => {
  if (loading) {
    return (<small style={{color: 'blue'}}>Carregando...</small>)
  }

  if (error) {
    return (<small style={{color: 'red'}}>Erro: { error }</small>);
  }

  return (<small style={{color: 'green'}}>Completo</small>);
}

const Placeholder = () => {
  return (<i>Não definido</i>);
}

const AboutPage = () => {
  const dashboardStatus = useFetchDashboardStatus();
  const apiStatus = useFetchApiStatus(API_ENDPOINT);

  return (
    <>
      <Layout>
        <Container>
          <Title title={'Status da aplicação'}></Title>
          <Body>
          <h4>Interface</h4>
          <span>Nome: { dashboardStatus.name || (<Placeholder />) }</span><br/>
          <span>Versão: { dashboardStatus.version || (<Placeholder />) }</span><br/>
          <StatusInfo { ...dashboardStatus } />

          <h4>API</h4>
          <span>URL: <a href={API_ENDPOINT}>{ API_ENDPOINT }</a></span><br/>
          <span>Status: { apiStatus.status || (<Placeholder />)  }</span><br/>
          <span>Versão: { apiStatus.version || (<Placeholder />) }</span><br/>
          <StatusInfo { ...apiStatus } />
          </Body>
        </Container>
      </Layout>
    </>
  )
}

export default AboutPage;