import React from 'react';

import Layout from '../../../components/DefaultLayout';
import InvitesPartial from '../../../components/StaffInvitesPartial';
import MembersPartial from '../../../components/StaffMembersPartial';

import { Container, Body, Title } from '../../../components/Layout/Page';
import { Holder } from './styles';

import useCurrentProjectId from '../../../hooks/useCurrentProjectId';

const MembersPage = () => {
  const currentProjectId = useCurrentProjectId();
  return (
    <Layout>
      <Container>
        <Title title={'Membros & Convites'} />
        <Body>
          <Holder>
            <MembersPartial projectId={currentProjectId} />
            <InvitesPartial projectId={currentProjectId} />
          </Holder>
        </Body>
      </Container>
    </Layout>
  )
}

export default MembersPage;