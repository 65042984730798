import React, { useCallback, useState, useEffect, useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { Hub } from 'aws-amplify';

import Context from '../contexts/Title';

import useCurrentUser from '../hooks/useCurrentUser';

const AppIconButton = styled(IconButton)`
  margin-left: -12px;
  margin-right: 20px;
`;

const AppTitle = styled.div`
  flex-grow: 1;
`;

const DashboardAppBarTitle = () => {
  const user = useCurrentUser();

  const handleSignOut = useCallback(() => {
    Hub.dispatch('session', { event: 'sign_out' });
  }, []);

  if (!user) {
    return (<span>Carregando...</span>);
  }

  return (
    <React.Fragment>
      <span>{user.email}</span>
      <Button color="secondary" variant="outlined" onClick={handleSignOut} style={{marginLeft: '8px'}}>Sair</Button>
    </React.Fragment>
  )
}

const AppBarTitle = () => {
  const [ title, setTitle ] = useState('');
  const appBarTitleContext = useContext(Context);

  useEffect(() => {
    if (!appBarTitleContext || !appBarTitleContext.subscribe) return;

    const handleTitleChanged = (text) => {
      setTitle(text);
    };

    return appBarTitleContext.subscribe(handleTitleChanged);
  }, [ appBarTitleContext ]);

  return (<span style={{marginLeft: '8px'}}>{title}</span>)
}

const DashboardAppBar = () => {

  const handleSidebarToggle = useCallback((e) => {
    // Code here
  }, []);

  return (
    <AppBar position="static">
      <Toolbar>
        <AppIconButton color="inherit" aria-label="Menu" onClick={handleSidebarToggle}>
          <MenuIcon />
        </AppIconButton>

        <AppTitle variant="h6" color="inherit">
          <AppBarTitle />
        </AppTitle>

        <DashboardAppBarTitle />
      </Toolbar>
    </AppBar>
  )
}

export default DashboardAppBar;