import React from 'react';
//import Item from './ItemB';
import Item from './Item';

const InviteList = (props) => {
  return (
    <div>
      {
        props.items.map((row, index) => (
          <Item
            key={index}
            project={row.project}
            inviter={row.inviter}
          />
        ))
      }
    </div>
  )
}

export default InviteList;