import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_KEY || 'no_api_key';

const bugsnagParams = {
  apiKey: BUGSNAG_API_KEY,
  enabledReleaseStages: ['production', 'staging']
};

const bugsnag = Bugsnag(bugsnagParams);
bugsnag.use(BugsnagPluginReact, React);

export default bugsnag;