import axios from 'axios';
import _ from 'lodash';
import authentication from '../shared/authentication';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api.esl.teewe.com.br';

export const authenticationResolver = (query, variables, request, cb) => {
  authentication.asyncGetIdToken()
    .then((token) => {
      cb(null, { type: 'Bearer', token: token });
    })
    .catch((error) => {
      cb(error);
    });
};

export const createRequest = (query, variables, options = {}) => {
  if (!options.endpoint) {
    options.endpoint = '/graphql/editor';
  }

  if (!options.authentication) {
    options.authentication = authenticationResolver;
  }

  if (!options.parseData) {
    options.parseData = (data, cb) => {
      cb(null, data);
    };
  }

  const pr = new Promise((resolve, reject) => {
    const handleError = (error) => {
      if (error.response) {
        reject(error.response.data);
      } else {
        reject(error);
      }
    };
  
    const handleSuccess = (response) => {
      resolve(response.data.data);
    };

    try {
      var requestParams = {
        method: 'post',
        url: `${API_ENDPOINT}${options.endpoint}`,
        data: {
          query: query,
          variables: variables
        },
        headers: {}
      };

      if (options.projectId) {
        requestParams.headers['X-Project-Id'] = options.projectId;
      }

      const onAuthorizationSuccess = (result) => {
        var axiosRequestParams = {
          ...requestParams,
          validateStatus: (statusCode) => {
            return statusCode >= 200 && statusCode < 400; // statusCode >= 200 && statusCode < 500;
          },
          headers: {
            'Authorization': `${result.type} ${result.token}`
          }
        };

        axios.request(axiosRequestParams)
          .then(handleSuccess)
          .catch(handleError);
      };
  
      const onAuthorizationError = (err) => {
        handleError(err);
      };

      const authorizationCallback = (err, authorization) => {
        if (err) {
          onAuthorizationError(err);
        } else {
          onAuthorizationSuccess(authorization);
        }
      }

      options.authentication(query, variables, requestParams, authorizationCallback);
      //.then(onAuthorizationSuccess)
      //.catch(onAuthorizationError);
    } catch (e) {
      handleError(e);
    }
  });

  pr.catch(_.noop);

  return pr;
}

export default createRequest;