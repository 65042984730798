import React from 'react';
import Layout from '../../../components/DefaultLayout';
import withCurrentProject from '../../../components/withCurrentProject';
import withApi from '../../../components/withApi';
import { Container, Body, Title } from '../../../components/Layout/Page';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyleHolder, Item } from './styles';

library.add(fas);

const ShapeInput = (props) => {
  return (
    <FormControl>
      <InputLabel>Formato</InputLabel>
      <Select value={props.value} onChange={props.onChange}>
        <MenuItem value="circle">Circulo</MenuItem>
        <MenuItem value="triangle">Triângulo</MenuItem>
        <MenuItem value="square">Quadrado</MenuItem>
      </Select>
    </FormControl>
  )
}

const StyleForm = (props) => {
  return (
    <>
      <FormControl>
        <TextField label="Cor de preenchimento" placeholder="padrão: cccccc" value={props.data.colorFill} />
      </FormControl>

      <FormControl>
        <TextField label="Cor de texto" placeholder="padrão: ffffff" value={props.data.colorText} />
      </FormControl>

      <FormControl>
        <TextField label="Ícone" placeholder="padrão: question" value={props.data.icon} />
      </FormControl>
      <ShapeInput value={props.data.shape} />
    </>
  )
}

const ServiceEmulator = (props) => {
  var styles = {
    backgroundColor: `${props.colorFill}`,
    border: `1px solid ${props.colorBorder}`,
    color: `${props.colorText}`,
    padding: '14px',
    borderRadius: '4px',
    maxWidth: '350px'
  };

  return (
    <div style={styles}>
      <FontAwesomeIcon icon={props.icon || 'question'} />
      <span style={{marginLeft: '14px'}}>{props.label}</span>
    </div>
  );
}

const PinEmulator = (props) => {
  var url = `${process.env.REACT_APP_API_ENDPOINT}/assets/markers/svg/${props.shape}.svg?color=${props.colorFill.substring(1)}&border=${props.colorBorder.substring(1)}`;
  return (<img src={url} alt="Pin emulator" width="32" height="32" />);
}

class ProjectSettingsPage extends React.PureComponent {

  state = {
    loading: true,
    error: null,
    data: null
  }

  componentDidMount () {
    const { apiClient } = this.props;

    apiClient.post('/graphql/query', {
      query: `
        query getStyles {
          getStyles {
            error {
              type
              message
            }
            data {
              count
              edges {
                id
                title
                selectorTypeId
                selectorStepId
                settings {
                  default {
                    found
                    data {
                      id
                      colorFill
                      colorText
                      colorBorder
                      icon
                      shape
                      pin(format: "PNG")
                    }
                  }
                  overdue {
                    found
                    data {
                      id
                      colorFill
                      colorText
                      colorBorder
                      icon
                      shape
                      pin(format: "PNG")
                    }
                  }
                }
              }
            }
          }
        }
      `
    })
    .then((httpResponse) => {
      const { getStyles } = httpResponse.data.data;
  
      if (getStyles.error) {
        return this.setState({
          loading: false,
          error: getStyles.error.type,
          data: null
        });
      }
  
      this.setState({
        loading: false,
        error: null,
        data: getStyles.data
      });
    })
    .catch((err) => {
      this.setState({
        loading: false,
        error: err.message || err.toString(),
        data: null
      })
    });
  }

  render () {    
    const renderContent = () => {
      if (this.state.loading) {
        return (<p>Carregando...</p>);
      }
  
      if (this.state.error) {
        return (<p>Error: {this.state.error}</p>);
      }
  
      return (
        <>
          {
            this.state.data.edges.map((item, index) => {
              return (
                <Item key={index}>
                  <h4>{item.title} <small>(id: {item.id})</small></h4>
                  <p>Tipo ID: {item.selectorTypeId}</p>
                  <p>Etapa ID: {item.selectorStepId}</p>
                  <br/>
        
                  <ServiceEmulator
                    colorFill={item.settings.default.data.colorFill}
                    colorBorder={item.settings.default.data.colorBorder}
                    colorText={item.settings.default.data.colorText}
                    icon={item.settings.default.data.icon}
                    label={item.title}
                  />

                  <StyleHolder>
                    <PinEmulator
                      colorFill={item.settings.default.data.colorFill}
                      colorBorder={item.settings.default.data.colorBorder}
                      shape={item.settings.default.data.shape}
                      icon={item.settings.default.data.icon}
                    />
                    <img src={item.settings.default.data.pin} alt="Atraso" />
                    <p>Normal <small>(styleId: {item.settings.default.data.id})</small></p>
                    <StyleForm data={item.settings.default.data} />
                  </StyleHolder>

                  <StyleHolder>
                    <PinEmulator
                      colorFill={item.settings.overdue.data.colorFill}
                      colorBorder={item.settings.overdue.data.colorBorder}
                      shape={item.settings.overdue.data.shape}
                      icon={item.settings.overdue.data.icon}
                    />
                    <img src={item.settings.overdue.data.pin} alt="Atraso" />
                    <p>Em atraso <small>(styleId: {item.settings.overdue.data.id})</small></p>
                    <StyleForm data={item.settings.overdue.data} />
                  </StyleHolder>
                </Item>
              )
            })
            }
        </>
      )
    }
    
    return (
      <Layout>
        <Container>
          <Title title={'Configurações'} />
          <Body>
            <h3>Estilos</h3>
            { renderContent() }
          </Body>
        </Container>
      </Layout>
    )
  }
}

export default withApi(withCurrentProject(ProjectSettingsPage));