import axios from 'axios';

// Services
import authentication from './shared/authentication';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api.esl.teewe.com.br';

export default async function (projectId) {
  const userId = authentication.getCurrentUser();
  const token = await authentication.asyncGetIdToken();

  const axiosParams = {
    baseURL: API_ENDPOINT,
    headers: {
      'X-Project-Id': projectId,
      'X-User-Id': userId,
      'Authorization': `Bearer ${token}`
    }
  };

  return axios.create(axiosParams);
};