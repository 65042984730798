import React from 'react';

// Components
import InviteCard from '../InviteCard';

import { Container } from './styles';

const InviteList = ({ items, onDelete, ...props }) => {
  items = items || [];

  const handleDelete = (invitation) => {
    return (e) => {
      if (onDelete) {
        onDelete(invitation, e);
      }
    }
  }

  return (
    <Container {...props}>
      {
        items.map((invitation) => {
          return (<InviteCard key={invitation.id} invitation={invitation} onDelete={handleDelete(invitation)} />)
        })
      }
    </Container>
  )
}

export default InviteList;