const INITIAL_STATE = {
  touched: false,
  loaded: false,
  error: undefined
};

/**
 * Application bootstrap reducer
 * @param {Object} state 
 * @param {Object} action 
 */
const appReducer = (state = INITIAL_STATE, action) => {
  if (action && action.type === 'APP.INIT') {
    return {
      touched: true,
      loaded: false,
      error: undefined
    }
  }

  if (action && action.type === 'APP.INIT_OK') {
    return {
      touched: true,
      loaded: true,
      error: null
    }
  }

  if (action && action.type === 'APP.INIT_ERROR') {
    return {
      touched: true,
      loaded: false,
      error: action.payload.error || 'unexpected_error'
    }
  }

  return state;
}

export default appReducer;