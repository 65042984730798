import { createContext } from 'react';

const subscriptions = [];

const appBar = {
  title: '',
  setTitle: (title) => {
    appBar.title = title;

    subscriptions
      .forEach(subscription => { subscription.bind(this, title)() });
  },
  subscribe: (handler) => {    
    subscriptions.push(handler);

    var unsubscribe = () => {
      var subscription = subscriptions.indexOf(handler);

      if (subscription !== -1) {
        subscriptions.splice(subscription, 1);
      }
    }

    return unsubscribe;
  }
};

export default createContext(appBar);