import React from 'react';

import Link from '../Link';
import withCurrentProject from '../../withCurrentProject';

const LinkProject = (props) => {
  var enabled = props.project && props.project.id;
  var preffix = '';

  if (enabled) {
    preffix = `/project/${props.project.id}`;
  }

  return (<Link {...props} href={`${preffix}${props.href}`} enabled={enabled} />)
};

export default withCurrentProject(LinkProject);