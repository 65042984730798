import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

export const StyledContainer = styled(Paper)`
  width: 100%;
  margin: 7px 0px;
  padding: 14px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & .invite-icon {
    margin-right: 8px;
  }

  & .invite-permission {
    margin-left: 16px;
    font-size: 80%;

  }
`