import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import authentication from '../../shared/authentication';

const useLocationParams = () => {
  const location = useLocation();
  const [ search, setSearch ] = useState(new URLSearchParams(location.search));

  useEffect(() => {
    setSearch(new URLSearchParams(location.search));
  }, [ location.search ]);

  return search;
}

const OAuthCallbackPage = (props) => {
  const locationParams = useLocationParams();
  const [ error, setError ] = useState(undefined);
  const [ isToRedirect, setIsToRedirect ] = useState(false);
  const refreshTokenValue = locationParams.get('refreshToken');

  useEffect(() => {
    var unsubscribe = false;

    if (!refreshTokenValue) {
      return;
    }

    authentication.asyncStartFromRefreshToken(refreshTokenValue)
      .then(() => {
        if (!unsubscribe) {
          setError(undefined);
          setIsToRedirect(true);
        }
      })
      .catch((err) => {
        setError(err);
      })

    return () => {
      unsubscribe = true;
    }
  }, [ refreshTokenValue ]);

  if (isToRedirect) {
    return (<Redirect to='/' />);
  }

  if (error) {
    return (
      <div>
        <h1>Oops! algo deu errado</h1>
        <span>Mensagem: { error.message || error.toString() }</span>
      </div>
    )
  }

  return (<span>Carregando credenciais...</span>);

  /*
  credentialManager.setCredentials({
    accessToken,
    refreshToken
  });

  const sessionUpdateEvent = {
    event: 'signed_in', // 'credentials_updated', 'start_session'
    source: 'oauth'
  };

  Hub.dispatch('session', sessionUpdateEvent);

  // TODO: Store credentials
  // TODO: Redirect home
  

  /*
  useEffect(() => {
    getUser(accessToken)
      .then((data) => {
        console.log(data);
        console.log(normalizeUserAttributes(data.UserAttributes));
      })
      .catch((err) => {
        console.error(err);
      });
  }, [ accessToken ]);
  
  return (
    <div>
      <h1>OAuthCallbackPage</h1>
      <span>Congratulations!...</span>
      <p>Access-token: {accessToken}</p>
      <p>Refresh-token: {refreshToken}</p>
      <p>Total: { accessToken.length + refreshToken.length }</p>
    </div>
  )
  */
}

export default OAuthCallbackPage;