import React from 'react';
import SidebarMenu from '../SidebarMenu';
import { Holder, Header } from './styles';

class DashboardSidebar extends React.Component {
  render = () => {
    return (
      <Holder>
        <Header>
          <span>
            <small>Engineering Service Location</small>
          </span>
        </Header>
        <div>
          <SidebarMenu />
        </div>
      </Holder>
    )
  }
}

export default DashboardSidebar;