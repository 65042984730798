import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';
import { Container, Row, ButtonHolder, FieldHolder } from './styles';

/**
 * Has error
 * @param {mixed} error 
 */
const hasError = (error) => {
  return Boolean(error);
}

const InviteBox = (props) => {

  const handleSubmit = (e) => {
    if (props.onSubmit) {
      props.onSubmit(e);
    }
  }

  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  }

  return (
    <Container className={ hasError(props.error) ? 'invite-box has-error' : 'invite-box' }>
      <Row>
        <FieldHolder>
          <TextField
            type="email"
            placeholder="Digite o email aqui"
            value={props.value}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            fullWidth
            disabled={props.sending}
          />
        </FieldHolder>

        <ButtonHolder>
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={props.sending}>
            <SendIcon />
            <span style={{marginLeft: '4px'}}>{ props.sending ? 'Convidando...' : 'Convidar' }</span>
          </Button>
        </ButtonHolder>
      </Row>
      {
        hasError(props.error) && 
        <Row>
          <small style={{color: '#A74344'}}>{props.error}</small>
        </Row>
      }
    </Container>
  )
}

InviteBox.propTypes = {
  value: PropTypes.string,
  sending: PropTypes.bool,
  error: PropTypes.string
}

export default InviteBox;