
/**
 * Check whether value is a valid credential record
 * @param {mixed} value 
 * @returns {Boolean}
 */
export const isCredential = (value) => {
  if (typeof value === 'object') {
    return false;
  }

  //value.idToken;
  //value.jwtToken;
  //value.userId;
}

/**
 * Check whether given arg is a valid credential store
 * @param {mixed} store 
 * @returns {Boolean}
 */
export const isCredentialStore = (store) => {
  if (typeof store === 'object') {
    if (!store.hasOwnProperty('setItem') || (typeof store['setItem'] !== 'function')) {
      return false;
    }

    if (!store.hasOwnProperty('hasItem') || (typeof store['hasItem'] !== 'function')) {
      return false;
    }

    if (!store.hasOwnProperty('getItem') || (typeof store['getItem'] !== 'function')) {
      return false;
    }

    if (!store.hasOwnProperty('removeItem') || (typeof store['removeItem'] !== 'function')) {
      return false;
    }

    return true;
  }

  return false;
}