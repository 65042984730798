import React from 'react';
import ProjectContext from '../contexts/Project';
import UploadContext from '../contexts/Upload';

class UploadProvider extends React.Component {
  
  constructor (props) {
    super(props);

    this.state = {
      upload: null // new UploadManager()
    }
  }

  render () {
    return (
      <UploadContext.Provider value={this.state.upload}>
        { this.props.children }
      </UploadContext.Provider>
    )
  }
}

class RootProvider extends React.Component {
  render = () => {
    var projectContext = {
      projectId: 'alpha',
      token: '123'
    };

    return (
      <UploadProvider>
        <ProjectContext.Provider value={projectContext}>
          {/*<GraphqlProvider>*/}
            { this.props.children }
          {/*</GraphqlProvider>*/}
        </ProjectContext.Provider>
      </UploadProvider>
    )
  }
}

export default RootProvider;