const initialState = {  
};

const actions = {
};

export default function (state = initialState, action) {
  if (actions[action.type]) {
    return actions[action.type](state, action);
  }

  return state;
}
