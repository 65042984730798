import styled from 'styled-components';

export const Holder = styled.div`
  overflow: hidden;
  box-sizing: border-box;
  height: 100vh;
  width: 40%;
  max-width: 300px;
  flex-shrink: 0;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.30);

  @media all and (max-width: 980px) {
    display: none;
  }
`

export const Header = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 64px;
  padding: 0 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  background-color: rgba(0, 0, 0, 0.07);
`;