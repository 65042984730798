import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import styled from 'styled-components';
import makeClient from '../../make-client';

const Permission = styled.div`
  padding: 7px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  &:last-of-type {
    border-bottom: none;
  }
`;

const PermissionControl = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PermissionTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

async function fetchPermissions (projectId, userId) {
  var client = await makeClient(projectId);
  
  var response = await client.post('/graphql/editor', {
    query: `
      query getMembers ($input: GetMembersInput!) {
        getMembers (input: $input) {
          error
          data {
            count
            edges {
              role
              permissions
            }
          }
        }
      }
    `,
    variables: {
      input: {
        id: userId
      }
    }
  });

  if (response.data.data.getMembers.data.edges.length === 0) {
    throw new Error('Member not found');
  }

  return response.data.data.getMembers.data.edges[0].permissions;
}

async function savePermissions (projectId, userId, permissions) {
  var client = await makeClient(projectId);

  var response = await client.post('/graphql/editor', {
    query: `
      mutation setMemberPermissions ($input: SetMemberPermissionsInput!) {
        setMemberPermissions(input: $input) {
          error
          data {
            count
            edges
          }
        }
      }
    `,
    variables: {
      input: {
        memberId: userId,
        permissions: permissions
      }
    }
  });

  if (response.data.data.setMemberPermissions.error) {
    throw new Error(response.data.data.setMemberPermissions.error);
  }

  return response.data.data.setMemberPermissions.data.edges;
}

function translatePermission (permission) {
  var i18n = {
    'manage.attachments': {
      title: 'Gerenciar arquivos',
      description: 'Adicionar e remover arquivos anexos para os serviços'
    },
    'view.module.inspection': {
      title: 'Módulo de inspeção (escrita)',
      description: 'Permite salvar dados no módulo de inspeção do ESL'
    }    
  };

  if (!i18n[permission]) {
    return {
      title: permission,
      description: 'Sem descrição'
    }
  }

  return i18n[permission];
}

function MemberPermissionsManager ({ projectId, memberId, ...props}) {
  const [ error, setError ] = React.useState(null);
  const [ saving, setSaving ] = React.useState(false);
  const [ touched, setTouched ] = React.useState(false);
  const [ userPermissions, setUserPermissions ] = React.useState([]);

  useEffect(() => {
    fetchPermissions(projectId, memberId)
      .then((data) => {
        setUserPermissions(data);
        setError(null);
      })
      .catch((err) => {
        setError(err.message || err.toString());
      });
  }, [ projectId, memberId ]);

  const handleClose = (e) => {
    if (props.onClose) {
      props.onClose(e);
    }
  };

  const handleSave = (e) => {
    setSaving(true);
    setError(null);

    savePermissions(projectId, memberId, userPermissions)
      .then((perms) => {
        setSaving(false);
        setTouched(false);
        setError(null);
      })
      .catch((e) => {
        setError(e.message || e.toString());
        setSaving(false);
      });
  }

  const handleSwitchChange = (permission) => {
    return (event) => {
      setTouched(true);
      var userPermissionsUpdated = [ ...userPermissions ];
      var permissionIndex = userPermissionsUpdated.indexOf(permission);

      if (event.target.checked) {
        // Enable
        if (permissionIndex === -1) {
          userPermissionsUpdated.push(permission);
          setUserPermissions(userPermissionsUpdated);
        }
      } else {
        // Disable
        if (permissionIndex !== -1) {
          userPermissionsUpdated.splice(permissionIndex, 1);
          setUserPermissions(userPermissionsUpdated); 
        }
      }
    }
  };

  var permissions = [
    'manage.attachments',
    'view.module.inspection',
    'manage.rackets',
    'manage.members',
    'manage.2',
    'manage.3',
    'manage.4',
    'manage.5',
    'manage.6',
    'manage.7',
    'manage.8'
  ];

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={Boolean(props.open)}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Gerenciar permissões do usuário</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Clique no switch ao lado de cada permissão para alternar ativação/desativação.
          </DialogContentText>
            { error && (<span style={{color: '#ff0000'}}>Oops! algo deu errado: { error }</span>) }
            {
              permissions.map((name) => (
                <Permission key={name}>
                  <PermissionControl>
                    <PermissionTitle>{translatePermission(name).title}</PermissionTitle>
                    <Switch checked={userPermissions.indexOf(name) !== -1} onChange={handleSwitchChange(name)} />
                  </PermissionControl>
                  <span>{translatePermission(name).description}</span>
                </Permission>
              ))
            }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary" disabled={saving || touched === false}>
           { saving ? 'Salvando...' : 'Salvar' }
          </Button>
          <Button onClick={handleClose} color="secondary" disabled={saving}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default MemberPermissionsManager;