import { CognitoIdentityServiceProvider } from 'aws-sdk';

const COGNITO_REGION = process.env.AWS_REGION || 'us-east-1';
const USER_POOL_CLIENT_ID = process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID || '';

const cognitoParams = {
  region: COGNITO_REGION
};

const cognito = new CognitoIdentityServiceProvider(cognitoParams);

/**
 * Exchange cognito refresh token
 * @param {String} refreshToken
 * @returns {Promise}
 */
export const exchangeRefreshToken = function (refreshToken) {
  const initiateAuthParams = {
    AuthFlow: 'REFRESH_TOKEN_AUTH',
    ClientId: USER_POOL_CLIENT_ID,
    AuthParameters: {
      REFRESH_TOKEN: refreshToken
    }
  };

  return cognito.initiateAuth(initiateAuthParams).promise();
}

/**
 * Fetch cognito user by accessToken
 * @param {String} accessToken
 * @returns {Promise}
 */
export const getUser = function (accessToken) {
  const getUserParams = {
    AccessToken: accessToken
  };

  return cognito.getUser(getUserParams).promise();
}

/**
 * Normalize user attributes from array to object
 * @param {Array[Object]} userAttributes 
 * @returns {Object}
 */
export const normalizeUserAttributes = (userAttributes) => {
  var attributes = Object.fromEntries(userAttributes.map(({ Name, Value }) => [Name, Value]));
  attributes.email_verified = attributes.email_verified === 'true';
  return attributes;
}

export default {
  exchangeRefreshToken,
  getUser,
  normalizeUserAttributes
}
