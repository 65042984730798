import styled from 'styled-components';

const Container = styled.div`
  height: 100%;

  @media (min-width: 1281px) and (max-width: 1500px) {
    .invite-box > div {
      flex-direction: column !important;
    }

    .invite-box div.MuiFormControl-root {
      margin-bottom: 5px;
    }
  }
`

const Body = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0px;
  padding: 0px;
  flex-direction: row;

  @media (max-width: 1365px) {
    flex-direction: column;
  }
`

const Box = styled.div`
  width: calc(100% - 32px);
  padding: 16px;
`;

const MainContent = styled.div`
  width: 100%;
  display: flex;

  @media (min-width: 1365px) {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    overflow-y: auto;
  }
`

const AsideContent = styled.div`
  width: 100%;
  max-width: 100%;

  @media (min-width: 1365px) {
    width: 40%;
    height: 100%;
    max-width: 550px;
    border-left: 1px solid #ccc;
    overflow-y: auto;
  }
`

export {
  Container,
  Body,
  Box,
  MainContent,
  AsideContent
}