const initialState = {
  details: {

  },
  invites: {
    fetched: false,
    error: null,
    data: {}
  }
};

const actions = {};

export default function (state = initialState, action) {
  if (actions[action.type]) {
    return actions[action.type](state, action);
  }

  return state;
}
