const initialState = {
  members: {
    byProjectId: {
      /**
       * id => {
       *   fetched: false,
       *   error: null,
       *   data: {}
       * }
       */
    }
  },
  invites: {
    byProjectId: {
      /**
       * id => {
       *   fetched: false,
       *   error: null,
       *   data: {}
       * }
       */
    }
  }
};

const actions = {
};

export default function (state = initialState, action) {
  if (actions[action.type]) {
    return actions[action.type](state, action);
  }

  return state;
}
