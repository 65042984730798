import React from 'react';
import axios from 'axios';

import useCurrentUser from '../hooks/useCurrentUser'
import useCurrentProjectId from '../hooks/useCurrentProjectId';
import useFetchProjectPublic from '../hooks/useProjectInfo';
import useToken from '../hooks/useToken';

if (!process.env.REACT_APP_API_ENDPOINT) {
  throw new TypeError('"REACT_APP_API_ENDPOINT" is not defined');
}

const withApi = (Component) => {
  return (props) => {
    const user = useCurrentUser();
    const projectId = useCurrentProjectId();
    const project = useFetchProjectPublic(user?.sub, projectId);
    const { idToken } = useToken();
    var client = null;

    if (user && project) {
      client = axios.create({
        baseURL: process.env.REACT_APP_API_ENDPOINT,
        headers: {
          'X-Project-Id': project.id,
          'X-User-Id': user.id,
          'Authorization': `Bearer ${idToken}`
        },
        json: true
      });
    }

    if (!user || !project || !idToken) {
      return (<span>Carregando cliente de API</span>);
    }

    return (<Component {...props} user={user} project={project} apiClient={client} />);
  }
}

export default withApi;
