import React from 'react';

// Helpers
import NonAuthenticatedRedirect from './NonAuthenticatedRedirect';

// Hooks
import useCurrentUserId from '../hooks/useCurrentUserId';

export const withSignedUserOnly = (Component) => {
  return (props) => {
    const currentUserId = useCurrentUserId();

    if (!currentUserId) {
      return (<NonAuthenticatedRedirect />);
    }

    return (<Component {...props} />)
  }
}

export default withSignedUserOnly;