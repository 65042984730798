import React from 'react';

// Components
import { Link } from 'react-router-dom';
import Layout from '../../components/DefaultLayout';
import { Container, Title, Body } from '../../components/Layout/Page';

const DashboardPage = (props) => {
  return (
    <Layout>
      <Container>
        <Title title={'Dashboard'}></Title>
        <Body>
          <span>Seja bem-vindo ao painel de administração do ESL.</span>
          <br />
          <br />
          <Link to='/about'>Status da aplicação</Link>
          {/*
          <br />
          <Link to='/sandbox'>Sandbox</Link>
          */}
        </Body>
      </Container>
    </Layout>
  )
}

export default DashboardPage;