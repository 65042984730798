import React, { useState, useEffect } from 'react';
import storage from '../../shared/storage';

// Components
import { Link } from 'react-router-dom';
import Layout from '../../components/DefaultLayout';
import { Container, Title, Body } from '../../components/Layout/Page';

const StorageValue = ({ name, ...props }) => {
  const [ value, setValue ] = useState('');
  const [ ttl, setTTL ] = useState(0);

  useEffect(() => {
    if (!name) return null;

    const updateState = () => {
      setValue(storage.getItem(name));
      setTTL(storage.getItemTTL(name));
    }

    updateState();
    var subscription = setInterval(updateState, 100);

    return () => {
      if (subscription) {
        clearInterval(subscription);
        subscription = undefined;
      }
    }
  }, [ name ]);

  return (
    <div>
      <p>name: { name || '' }</p>
      <p>value: { value || '' }</p>
      <p>ttl: { ttl / 1000 } seconds</p>
    </div>
  )
}

const StorageItem = ({ name, ...props }) => {
  const [ inputValue, setInputValue ] = useState('');

  const handleChange = (e) => {
    setInputValue(e.target.value);
  }

  const handleSubmit = () => {
    storage.setItem(name, inputValue, 60000);
  }

  const handleUpgrade = () => {
    storage.setItemTTL(name, 30000);
  }

  return (
    <div>
      <StorageValue name={name} />
      <input value={inputValue} onChange={handleChange} />
      <button onClick={handleSubmit}>Update value</button>
      <button onClick={handleUpgrade}>Add +30s</button>
    </div>
  )
}

const SandboxPage = (props) => {
  const userId = 'b9fceb83-effe-459e-bf95-d4a7e1e48edc';

  return (
    <Layout>
      <Container>
        <Title title={'Sandbox'}></Title>
        <Body>
          <h1>Sandbox page</h1>
          <StorageItem name={`user.${userId}.profile`} />
          <StorageItem name={`user.${userId}.refreshToken`} />
          <StorageItem name={`user.${userId}.accessToken`} />
          <StorageItem name={`user.${userId}.idToken`} />
        </Body>
      </Container>
    </Layout>
  )
}

export default SandboxPage;