import React from 'react';

import Layout from '../../components/DefaultLayout';
import { Container, Body, Title } from '../../components/Layout/Page';

const Error4xx = () => {
  return (
    <Layout>
      <Container>
        <Body>
            <Title title="Página não encontrada" />
            <h2>Erro 404 - <small>Página não encontrada</small></h2>
            <span>A página que vocês está procurando não existe ou foi temporariamente desabilitada.</span>
        </Body>
      </Container>
    </Layout>
  );
}

export default Error4xx;