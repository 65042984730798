import styled from 'styled-components';

export const ButtonIcon = styled.div`
  display: flex;
  margin-right: 7px;
`;

export const ButtonsHolder = styled.div`
  & > button {
    margin-right: 8px;
  }

  % > button:last-of-type {
    margin-right: 0px;
  }
`;

export const UserDetailHolder = styled.div`

`

export const UserDetailInfo = styled.div`
  margin-bottom: 8px;
`