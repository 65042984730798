import React from 'react';
import { List, ListSubheader } from '@material-ui/core';

import withCurrentProject from '../components/withCurrentProject';
import items from '../menu.js';

class SidebarMenu extends React.PureComponent {

  renderProjectMenu = () => {
    if (!this.props.project) {
      return null;
    }

    return (
      <List key="context:project" component="nav" subheader={(<ListSubheader component="div">Projeto: <small>{this.props.project.name}</small></ListSubheader>)}>
        {
          items
            .filter((item) => item.context === 'project')
            .map((item, index) => (<div key={index}>{item.component}</div>))
        }
      </List>
    )
  }

  renderUserMenu = () => {
    return (
      <List key="context:account" component="nav" subheader={(<ListSubheader component="div">Usuário</ListSubheader>)}>
        {
          items
            .filter((item) => item.context === 'user')
            .map((item, index) => (<div key={index}>{item.component}</div>))
        }
      </List>
    )
  }

  render = () => {
    return (
      <>
        { this.renderUserMenu() }
        { this.renderProjectMenu() }    
      </>
    )
  }  
}

export default withCurrentProject(SidebarMenu);