import styled from 'styled-components';

export const StyleHolder = styled.div`
  background-color: #ccc;
  margin: 8px 0px;
  padding: 14px;
  width: 100%;
`;

export const Item = styled.div`
  margin-top: 14px;
  border-bottom: 1px solid rgba(0,0,0,0.15);
  padding: 8px 14px;
`;