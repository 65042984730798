import React from 'react';

import withCurrentProject from './withCurrentProject';

// Components
import Layout from './DefaultLayout';
import { Container, Body, Title } from './Layout/Page';

const withRequiredProject = (Component) => {
  return withCurrentProject((props) => {
    if (!props.project || !props.project.id) {
      return (
        <Layout>
          <Container>
            <Body>
              <Title title="Acesso negado" />
              <h2>Erro 403 - Acesso negado</h2>
              <p>Você precisa selecionar um projeto para ter acesso a esse conteúdo.</p>
            </Body>
          </Container>
        </Layout>
      );
    }

    return (<Component {...props} />)
  })
}

export default withRequiredProject;