import React, { useEffect } from 'react';
import qs from 'querystring';
//import { useLocation, useRouteMatch } from 'react-router-dom';
//import { useState, useEffect } from 'react';

//import Redirect from '../Redirect';
const ACCOUNTS_ENDPOINT = process.env.REACT_APP_ACCOUNTS_ENDPOINT || 'https://accounts.esl.teewe.com.br';
const BASENAME = process.env.REACT_APP_BASENAME || '';
const USER_POOL_CLIENT_ID = process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID || '';

const ForceRedirectToSignIn = (props) => {
  // <Redirect url={`${process.env.REACT_APP_AUTH_HOST}/#/sign-in?redirectUri=${encodeURIComponent(window.location.toString())}`} />
  // const redirectUrl = `${process.env.REACT_APP_AUTH_HOST}/#/sign-in?redirectUri=${encodeURIComponent(window.location.toString())}`;

  useEffect(() => {
    var unsubscribed = false;

    var querystringParams = {
      clientId: USER_POOL_CLIENT_ID,
      callbackUri: `${window.location.protocol}//${window.location.host}/${BASENAME}#/oauth/callback`
    };
  
    var redirectUrl = `${ACCOUNTS_ENDPOINT}/#/oauth?${qs.stringify(querystringParams)}`;

    setTimeout(() => {
      if (unsubscribed) return;
      window.location.href = redirectUrl;
    }, 3000);

    return () => {
      unsubscribed = true;
    }
  }, []);

  /*
  <span>Para acessar a página requisitada é necessário estar logado.</span>
  <span>Você será redirecionado para página de login dentro de alguns instantes.</span>
  */

  return (
    <>
      <span>Redirecionando para a página de login...</span><br/>
    </>
  )
}

export default ForceRedirectToSignIn;