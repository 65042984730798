import { useState, useEffect } from 'react';
//import { Hub } from 'aws-amplify';

//import session from '../shared/session';
import authentication from '../shared/authentication';

const useToken = () => {
  const [ accessToken, setAccessToken ] = useState(undefined);
  const [ idToken, setIdToken ] = useState(undefined);

  useEffect(() => {    
    var unsubscribe = false;

    var updateTokens = ({ accessTokenValue, idTokenValue }) => {
      if (!unsubscribe) {
        setAccessToken(accessTokenValue);
        setIdToken(idTokenValue);
      }
    }

    var eventHandler = (e) => {
      if (!unsubscribe) {
        return;
      }

      if (e.name === 'access_token') {
        setAccessToken(e.value);
      }

      if (e.name === 'id_token') {
        setIdToken(e.value);
      }
    }

    authentication.events.on('data', eventHandler);

    (async () => {
      var accessTokenValue = await authentication.asyncGetAccessToken(true);
      var idTokenValue = await authentication.asyncGetIdToken();
      updateTokens({ accessTokenValue, idTokenValue });
    })();

    /*
    var handler = ({ payload }) => {
      if (payload.event === 'credential_updated' && (payload.param === 'access_token' || payload.param === 'id_token')) {
        setAccessToken(session.accessToken);
        setIdToken(session.idToken);
      }
    }

    Hub.listen('session', handler);
    */

    return () => {
      //Hub.remove('session', handler);
      unsubscribe = true;
      authentication.events.off('data', eventHandler);
    }
  }, []);

  return { accessToken, idToken };
}

export default useToken;