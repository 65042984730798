import React from 'react';

import useCurrentProjectId from '../hooks/useCurrentProjectId';
import useCurrentUserId from '../hooks/useCurrentUserId';
import useProjectInfo from '../hooks/useProjectInfo';

const withCurrentProject = (Component) => {
  return (props) => {
    const userId = useCurrentUserId();
    const projectId = useCurrentProjectId();
    const project = useProjectInfo(userId, projectId);

    var user = {
      id: userId,
      name: 'USER-123'
    };

    return (<Component {...props} user={user} project={project} />);
  }
}

export default withCurrentProject;
