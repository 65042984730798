import React from 'react';
import { AvatarContainer } from './styles';

function User ({ error, data }) {
  if (error) {
    return (<p>Usuário apagado</p>);
  }

  return (
    <AvatarContainer>
      <img
        src={data.avatarUrl}
        alt={`Avatar de "${data.name}"`}
        title={`Avatar de "${data.name}"`}
        width="40"
      />
      <span className="username" title={data.name}>{data.name}</span>
    </AvatarContainer>
  )
}

export default User;