import React from 'react';

// Dashboard layouts
import DashboardSidebar from '../../dashboard/Sidebar';
import DashboardContent from '../../dashboard/Content';
import DashboardRoot from '../DashboardRoot';

const Layout = (props) => {
  return (
    <DashboardRoot>
      <DashboardSidebar />
      <DashboardContent>{ props.children }</DashboardContent>
    </DashboardRoot>
  );
}

export default Layout;