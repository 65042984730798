/*
const TTLStorage = (storage) => {
  const parseKey = (key) => {
    return `time_storage_ttl.${key}`;
  }

  const getItemTTL = (key) => {
    var expiration = parseInt(storage.getItem(parseKey(key)));

    if (isNaN(expiration)) {
      return null; // No expiration
    }

    var now = (new Date()).getTime();
    var ttl = expiration - now;

    if (ttl < 0) {
      return 0;
    }

    return ttl;
  }
  
  const setItemTTL = (key, ttl = null) => {
    if (!ttl) {
      removeItemTTL(key);
      return;
    }

    storage.setItem(parseKey(key), (new Date()).getTime() + ttl);
  }

  const removeItemTTL = (key) => {
    storage.removeItem(parseKey(key));
  }

  const setItem = (key, value, ttl = null) => {
    setItemTTL(key, ttl);
    storage.setItem(key, value);
  }

  const getItem = (key) => {
    var ttl = getItemTTL(key);

    if (ttl !== null && ttl <= 0) {
      removeItem(key);
    }

    return storage.getItem(key);
  }

  const removeItem = (key) => {
    removeItemTTL(key);
    storage.removeItem(key);
  }

  const hasItem = (key) => {
    if (isExpired(key)) {
      removeItem(key);
      return false;
    }

    return storage.hasItem(key);
  }

  const isExpired = (key) => {
    return getItemTTL(key) <= 0;
  }

  return {
    getItemTTL,
    setItemTTL,
    removeItemTTL,
    setItem,
    hasItem,
    getItem,
    removeItem,
    isExpired
  }
}
*/

/**
 * Validate adapter
 * @throws {TypeError} When adapter is invalid
 * @param {Adapter} adapter 
 */
export const validateAdapter = (adapter) => {
  var requiredMethods = [
    'setItemTTL',
    'getItemTTL',
    'hasItemTTL',
    'removeItemTTL',
    'setItemValue',
    'getItemValue',
    'hasItemValue',
    'removeItemValue'
  ];

  if (typeof adapter !== 'object') {
    throw new TypeError('Adapter must be a valid object');
  }

  for (var i = 0; i < requiredMethods.length; i++) {
    let propName = requiredMethods[i];

    if (!adapter.hasOwnProperty(propName)) {
      throw new TypeError(`Adapter method "${propName}"`);
    }

    if (typeof adapter[propName] !== 'function') {
      throw new TypeError(`Adapter method "${propName}" must be a valid function`);
    }
  }
}

/**
 * Check whether adapter is valid
 * @param {Adapter} adapter 
 * @returns {Boolean} true if adapter is valid
 */
export const isValidAdapter = (adapter) => {
  try {
    validateAdapter(adapter);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * Parse TTL to Unix Timestamp
 * @param {Number} ttl Expiration in milliseconds
 * @returns {Number} Unix timestamp
 */
export const parseTTL2Timestamp = (ttl) => {
  if (ttl === null) return null;
  return (new Date()).getTime() + ttl;
}

/**
 * Parse Unix timestamp to TTL
 * @param {Number} timestamp 
 * @returns {Number} Expiration in milliseconds
 */
export const parseTimestamp2TTL = (timestamp) => {
  if (!timestamp) {
    return null;
  }

  var expiration = parseInt(timestamp);

  if (isNaN(expiration)) {
    return null; // No expiration
  }

  var now = (new Date()).getTime();
  var ttl = expiration - now;

  if (ttl < 0) return 0;
  return ttl;
}

/**
 * Check whether is expired
 * @param {Adapter} adapter 
 * @param {String} key 
 * @returns {Boolean} true whether item is expired
 */
const _isExpiredItem = (adapter, key) => {
  var ttl;
  var parsedTTL;
  ttl = adapter.getItemTTL(key);

  if (ttl === null) return false;

  parsedTTL = parseTimestamp2TTL(ttl);
  return parsedTTL <= 0;
}

/**
 * Remove item
 * @param {Adapter} adapter 
 * @param {String} key 
 */
const _removeItem = (adapter, key) => {
  adapter.removeItemTTL(key);
  adapter.removeItemValue(key);
}

/**
 * Return item value or remove if expired
 * @param {Adapter} adapter 
 * @param {String} key 
 * @returns {String|null} Item value
 */
const _getItemValue = (adapter, key) => {
  if (_isExpiredItem(adapter, key)) {
    _removeItem(adapter, key);
    return null;
  }

  return adapter.getItemValue(key);
}

const _setItemTTL = (adapter, key, ttl) => {
  if (isFinite(ttl) && ttl > 0) {
    adapter.setItemTTL(key, parseTTL2Timestamp(ttl));
    return;
  }

  if (ttl === null) {
    adapter.removeItemTTL(key); 
  }
}

export const TimeLivedStorage = (adapter) => {
  if (!isValidAdapter(adapter)) {
    throw new TypeError('Invalid adapter', adapter);
  }

  return {
    setItemTTL: (key, ttl) => {
      //adapter.setItemTTL(key, parseTTL2Timestamp(ttl));
      _setItemTTL(adapter, key, ttl);
    },
    getItemTTL: (key) => {
      return parseTimestamp2TTL(adapter.getItemTTL(key));
    },
    hasItemTTL: (key) => {
      return adapter.hasItemTTL(key);
    },
    removeItemTTL: (key) => {
      adapter.removeItemTTL(key);
    },

    /**
     * Get item TTL without cleanup
     * @param {String} key
     * @returns {Number} 
     */
    peakItemTTL: (key) => {
      return parseTimestamp2TTL(adapter.getItemTTL(key));
    },

    /**
     * Peak item value without expiring
     * @param {String} key 
     * @returns {String|null}
     */
    peakItemValue: (key) => {
      return adapter.getItemValue(key);
    },

    /**
     * Check whether item is expired without perform clean-up
     * @param {String} key 
     * @returns {Boolean} true if not expired
     */
    peakItemExpiration: (key) => {
      return !_isExpiredItem(adapter, key);
    },

    /**
     * Set item value and ttl
     * @param {String} key 
     * @param {String} value 
     * @param {Number|null} ttl (Optional) defaults to never expires
     */
    setItem: (key, value, ttl = null) => {
      _setItemTTL(adapter, key, ttl);
      //adapter.setItemTTL(key, parseTTL2Timestamp(ttl));
      adapter.setItemValue(key, value);
    },

    /**
     * Return item value (And remove if item is expired)
     * @param {String} key 
     * @returns {String|null}
     */
    getItem: (key) => {
      return _getItemValue(adapter, key);
    },

    /**
     * Check item exists
     * @param {String} key 
     * @returns {Boolean} true if item exists and not expired
     */
    hasItem: (key) => {
      if (!adapter.hasItemValue(key)) return false;

      if (_isExpiredItem(adapter, key)) {
        adapter.removeItemTTL(key);
        adapter.removeItemValue(key);
        return false;
      }

      return true;
    },

    /**
     * Remove item
     * @param {String} key 
     */
    removeItem: (key) => {
      adapter.removeItemTTL(key);
      adapter.removeItemValue(key);
    },

    /**
     * Return item or get value
     * @param {String} key 
     * @param {Number|null} ttl Expiration in milliseconds (null = never expires)
     * @param {Function} fallback Function to execute if none value is found
     * @returns {Promise}
     */
    getItemWithFallback: (key, ttl, fallback) => {
      var value = _getItemValue(adapter, key);

      if (value === null) {
        return new Promise((resolve, reject) => {
          var callback = (err, data) => {
            if (err) {
              reject(err);
              return;
            }

            adapter.setItemTTL(key, parseTTL2Timestamp(ttl));
            adapter.setItemValue(key, data);

            resolve(data);
          };
  
          fallback(callback);
        });
      }

      return Promise.resolve(value);
    }
  }
}

export const BrowserAdapter = (params) => {
  const config = {
    prefix: params.prefix || 'time-lived-storage'
  };

  return {
    setItemTTL: (key, timestamp) => {
      localStorage.setItem(`${config.prefix}.ttl.${key}`, timestamp);
    },
    getItemTTL: (key) => {
      return localStorage.getItem(`${config.prefix}.ttl.${key}`);
    },
    hasItemTTL: (key) => {
      return localStorage.hasItem(`${config.prefix}.ttl.${key}`);
    },
    removeItemTTL: (key) => {
      localStorage.removeItem(`${config.prefix}.ttl.${key}`);
    },
    setItemValue: (key, value) => {
      localStorage.setItem(`${config.prefix}.val.${key}`, value);
    },
    getItemValue: (key) => {
      return localStorage.getItem(`${config.prefix}.val.${key}`);
    },
    hasItemValue: (key) => {
      return localStorage.hasItem(`${config.prefix}.val.${key}`);
    },
    removeItemValue: (key) => {
      localStorage.removeItem(`${config.prefix}.val.${key}`);
    }
  }
}

export default {
  validateAdapter,
  isValidAdapter,
  TimeLivedStorage,
  BrowserAdapter
}

/*
window.ttlStorage = TTLStorage(localStorage);
*/