import React from 'react';
import Layout from '../DefaultLayout';
import { Container, Body, Title } from '../Layout/Page';

const UnderConstruction = (props) => {
  return (
    <Layout>
      <Container>
        <Body>
          <Title title="Página em construção" />
          <h2>Página em construção</h2>
          <span>Está página está sendo construída.</span>
        </Body>
      </Container>
    </Layout>
  )
}

export default UnderConstruction;