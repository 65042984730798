import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import User from './User';
import translatePermission from '../../i18n/permissions';
import PermissionsModal from '../../modals/MemberPermissionsManager';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: '980px',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});

const MemberList = (props) => {
  const classes = useStyles();
  const [ selected, setSelected ] = React.useState(null);

  /**
   * Handle delete
   * @param {Object} item 
   */
  const handleDelete = (item) => {
    return (e) => {
      var itemsUpdated = [ ...props.rows ];
      itemsUpdated.splice(itemsUpdated.indexOf(item), 1);

      if (props.onChange) {
        props.onChange(itemsUpdated, props.rows); // updated, original
      }
    }
  }

  const handleOpen = (item) => {
    return (e) => {
      setSelected(item.user.data.id);
    }
  }

  const handleClose = () => {
    setSelected(null);
  }

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="Lista de membros">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell align="right">Papel</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            props.rows.map((member, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <User error={member.user.error} data={member.user.data} />
                </TableCell>
                <TableCell align="right">{translatePermission(member.permission.toUpperCase())}</TableCell>
                <TableCell align="right">
                  <Button size="small" onClick={handleOpen(member)} disabled={Boolean(member.user.error)}>Gerenciar permissões</Button>
                  <Button size="small" onClick={handleDelete(member)} disabled={Boolean(member.user.error)}>Excluir do projeto</Button>
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
      <PermissionsModal
        open={Boolean(selected)}
        projectId={props.projectId}
        memberId={selected}
        onClose={handleClose}
      />
    </Paper>
  )
}

export default MemberList;