import { isCredentialStore } from './utils';
import { CookieStorage as CookieStorageHelper } from 'cookie-storage';

export class CredentialManager {

  _storage = null;

  /**
   * CredentialStorage
   * @param {Object} params
   * @param {CredentialStorage} params.storage
   */
  constructor (params = {}) {
    if (!params.storage) {
      throw new Error('Missing parameter "storage"');
    }

    this._setStorage(params.storage);
  }

  _setStorage = (storage) => {
    if (!isCredentialStore(storage)) {
      throw new Error('Invalid parameter "storage"');
    }

    this._storage = storage;
  }

  _getStorage = () => {
    return this._storage;
  }

  /**
   * Save credentials
   * @param {Object} credentials
   * @param {String} credentials.userId
   * @param {String} credentials.idToken
   * @param {String} credentials.accessToken
   * @param {String} credentials.refreshToken
   * @returns {Boolean}
   */
  setCredentials = (credentials) => {
    //this._getStorage().setItem('app.signedUser', credentials.userId);
    //this._getStorage().setItem('session.userId', credentials.userId);
    //this._getStorage().setItem('session.idToken', credentials.idToken);
    this._getStorage().setItem('session.accessToken', credentials.accessToken);
    this._getStorage().setItem('session.refreshToken', credentials.refreshToken);
    return true;
  }

  setUserItem = (userId, key, value) => {
    if (!userId || userId.lenght === 0) {
      throw new Error('Missing argument "userId"');
    }
    return this._getStorage().setItem(`user.${userId}.${key}`, value);
  }

  hasUserItem = (userId, key) => {
    if (!userId || userId.lenght === 0) {
      throw new Error('Missing argument "userId"');
    }
    return this._getStorage().hasItem(`user.${userId}.${key}`);
  }

  getUserItem = (userId, key) => {
    if (!userId || userId.lenght === 0) {
      throw new Error('Missing argument "userId"');
    }
    return this._getStorage().getItem(`user.${userId}.${key}`);
  }

  removeUserItem = (userId, key) => {
    if (!userId || userId.lenght === 0) {
      throw new Error('Missing argument "userId"');
    }
    return this._getStorage().removeItem(`user.${userId}.${key}`);
  }

  /**
   * Check whether user has credentials
   * @returns {Boolean}
   */
  hasCredentials = () => {
    return this.getCredentials() !== null;
  }

  /**
   * Return current credentials
   * @returns {Object}
   */
  getCredentials = () => {
    if (!this._getStorage().hasItem('session.accessToken') && !this._getStorage().hasItem('session.refreshToken')) {
      return null;
    }

    return {
      //userId: this._getStorage().getItem('session.userId'),
      //idToken: this._getStorage().getItem('session.idToken'),
      accessToken: this._getStorage().getItem('session.accessToken'),
      refreshToken: this._getStorage().getItem('session.refreshToken')
    }
  }
  
  clearCredentials = () => {
    this._getStorage().removeItem('session.userId');
    this._getStorage().removeItem('session.idToken');
    this._getStorage().removeItem('session.accessToken');
    this._getStorage().removeItem('session.refreshToken');
  }

  /**
   * Set last user ID
   * @param {String} userId 
   */
  setLastUserId = (userId) => {
    this._getStorage().setItem('currentUser', userId);
  }

  /**
   * Returns last active user ID
   * @returns {String|null} userId
   */
  getLastUserId = () => {
    if (!this._getStorage().hasItem('currentUser')) {
      return null;
    }

    return this._getStorage().getItem('currentUser');
  }
}

export class BrowserStorage {

  setItem = (key, value) => {
    return window.localStorage.setItem(key, value);
  }

  hasItem = (key) => {
    return window.localStorage.getItem(key) !== null;
  }

  removeItem = (key) => {
    return window.localStorage.removeItem(key);
  }

  getItem = (key) => {
    return window.localStorage.getItem(key);
  }
}

export class CookieStorage {

  constructor (params = {}) {
    this._storage = new CookieStorageHelper(params || {});
  }

  setItem = (key, value) => {
    this._storage.setItem(key, value);
  }

  hasItem = (key) => {
    return this.getItem(key) !== null;
  }

  getItem = (key) => {
    return this._storage.getItem(key);
  }

  removeItem = (key) => {
    this._storage.removeItem(key);
  }
}

export class ScopedCredentialStorage {

  _scope = null;
  _storage = null
  
  constructor (params = {}) {
    if (!params.scope) {
      throw new Error('Missing parameter "scope"');
    }

    if (!params.storage) {
      throw new Error('Missing parameter "storage"');
    }

    this._scope = params.scope;
    this._storage = params.storage;
  }

  _getKey = (key) => {
    return `${this._scope}.${key}`;
  }

  setItem = (key, value) => {
    return this._storage.setItem(this._getKey(key), value);
  }

  getItem = (key) => {
    return this._storage.getItem(this._getKey(key));
  }

  hasItem = (key) => {
    return this._storage.hasItem(this._getKey(key));
  }

  removeItem = (key) => {
    return this._storage.removeItem(this._getKey(key));
  }
}

export class MemoryStorage {

  constructor () {
    this._data = {};
  }

  setItem = (key, value) => {
    this._data[key] = value;
  }

  getItem = (key) => {
    if (this.hasItem(key)) {
      return this._data[key];
    }

    return null;
  }

  hasItem = (key) => {
    return Object.keys(this._data).indexOf(key) !== -1 && this._data[key] !== undefined;
  }

  removeItem = (key) => {
    return delete(this._data[key]);
  }
}

export default {
  CredentialManager,
  BrowserStorage,
  CookieStorage,
  MemoryStorage,
  ScopedCredentialStorage
}