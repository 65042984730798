import React from 'react';
import { connect } from 'react-redux';

import Amplify from '../../shared/amplify';
import userPreferences from '../../shared/user-preferences';

class UserLoader extends React.PureComponent {

  fetchUser () {
    (async () => {
      try {
        var userSession = await Amplify.Auth.currentSession();

        var id = userSession.idToken.payload.sub;
        var token = userSession.idToken.jwtToken;
        var info = userSession.idToken.payload;
        var defaultProjectId = userPreferences.getItem(id, 'defaultProjectId');

        this.props.dispatch({
          type: 'USERS.SET_USER',
          value: {
            id,
            info: info,
            token: token,
            defaultProjectId: defaultProjectId
          }
        });

        this.props.dispatch({
          type: 'USERS.SET_CURRENT_USER',
          value: id
        });

        this.props.dispatch({
          type: 'USERS.SET_USER_RESPONSE',
          value: {
            id,
            fetched: true,
            error: null
          }
        });
        
        this.props.dispatch({
          type: 'USERS.LOAD_PROJECTS'
        });
        
      } catch (e) {

        if (id) {
          this.props.dispatch({
            type: 'USERS.SET_USER_RESPONSE',
            value: {
              id,
              fetched: true,
              error: e.message
            }
          });
        }
      }
    })();
  }

  render () {
    /*
    if (!this.props.users.currentUserId || !this.props.users.data[this.props.users.currentUserId]) {
      this.fetchUser();
      return 'Carregando dados do usuário...';
    }
    */
    return this.props.children;
  }
}

export default connect((state) => {
  return {
    users: state.users
  }
})(UserLoader);
