import axios from 'axios';
import { all, takeLatest, put, call, select } from 'redux-saga/effects';

//import getCurrentUser from '../selectors/getCurrentUser';
//import getCurrentProject from '../selectors/getCurrentProject';

/**
 * Load projects from API
 * @param {String} token
 */
async function loadProjectsApi (token) {
  var response = await axios({
    url: `${process.env.REACT_APP_API_ENDPOINT}/graphql/user`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`
    },
    json: true,
    data: {
      query: `
        query getUserProjects {
          getProjects {
            error {
              type
            }
            data {
              count
              edges {
                permission
                project {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables: {}
    }
  });

  const { getProjects } = response.data.data;

  if (getProjects.error) {
    throw new Error(getProjects.error.type);
  }

  return getProjects.data.edges.map((item) => {
    return {
      id: item.project.id,
      name: item.project.name,
      permission: item.permission
    }
  });
}

/**
 * Load projects
 * @param {Object} action 
 */
function* asyncLoadProjects (action) {
  var stateUsers = yield select(state => state.users);
  var { currentUserId } = stateUsers;

  yield put({
    type: 'USERS.SET_PROJECTS_RESPONSE',
    value: {
      user: currentUserId,
      response: {
        fetched: false,
        error: null
      }
    }
  });

  try {
    var user = stateUsers.data[currentUserId];
    var response = yield call(loadProjectsApi, user.token);

    yield put({
      type: 'USERS.SET_PROJECTS',
      value: {
        user: currentUserId,
        projects: Object.fromEntries(response.map((project) => {
          return [project.id, project];
        }))
      }
    });

    yield put({
      type: 'USERS.SET_PROJECTS_RESPONSE',
      value: {
        user: currentUserId,
        response: {
          fetched: true,
          error: null
        }
      }
    });
  } catch (e) {
    yield put({
      type: 'USERS.SET_PROJECTS_RESPONSE',
      value: {
        user: currentUserId,
        response: {
          fetched: true,
          error: e.message
        }
      }
    });
  }
}

export default function* sagaRoot () {
  yield all([
    takeLatest('USERS.LOAD_PROJECTS', asyncLoadProjects)
  ]);
}
