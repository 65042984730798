import { useCallback } from 'react';
import axios from 'axios';

import authentication from '../shared/authentication';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api.esl.teewe.com.br';

const useGraphQLEditorApi = (projectId) => {
  const graphqlQuery = useCallback(({ query, variables = {} }) => {
    return new Promise(async (resolve, reject) => {
      //const projectId = '85d35927-9fd6-471c-9ef8-46246a47529b'; // 'ffadc1b3-de2f-47b0-9e62-ee5df438562f'; // 'PROJECT_ID_123'; // TODO: Resolve current selected project id
      //const token = session.idToken; // TODO: Resolve accessToken | idToken
      const token = await authentication.asyncGetIdToken();

      var requestParams = {
        method: 'post',
        url: `${API_ENDPOINT}/graphql/editor`,
        data: {
          query: query,
          variables: variables
        },
        validateStatus: (statusCode) => {
          return statusCode >= 200 && statusCode < 400; // statusCode >= 200 && statusCode < 500;
        },
        headers: {
          'X-Project-Id': projectId,
          'Authorization': `Bearer ${token}`
        }
      };

      var requestPromise = axios.request(requestParams);

      requestPromise.then((response) => {
        resolve(response.data.data);
      });

      requestPromise.catch((error) => {
        if (error.response) {
          reject(error.response.data);
          return;
        }

        reject(error);
      });
    });
  }, [ projectId ]);

  const graphqlMutation = useCallback((query, variables= {}) => {
    // Code here
  }, [ ]);

  return {
    query: graphqlQuery,
    mutation: graphqlMutation
  }
}

export default useGraphQLEditorApi;

/*
const api = useGraphQLEditorApi();
api.query(``).then(({ getMembers }) => {
  
})
*/