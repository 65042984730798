import React, { useState, useEffect, useCallback } from 'react';

import Container from '../StaffInviteList';
import AddInviteForm from '../AddInviteForm';
import { Box, AsideContent } from './styles';

import useGraphQLEditorAPI from '../../hooks/useGraphQLEditorAPI';

const fetchProjectInvitesQuery = `
  query getInvites ($input: GetInvitesInput!) {
    getInvites (input: $input) {
      error
      data {
        count
        edges {
          id
          email
          permission
          requester {
            data {
              user {
                id
                name
              }
            }
          }
          createdAt
        }
      }
    }
  }
`;

const useFetchProjectInvites = (projectId, page = 1, perPage = 5) => {
  const [ touched, setTouched ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(undefined);
  const [ response, setResponse ] = useState(undefined);
  const [ shouldForceUpdate, setShouldForceUpdate ] = useState(false);

  const graphQLEditor = useGraphQLEditorAPI(projectId);

  useEffect(() => {
    if (shouldForceUpdate === true) {
      setShouldForceUpdate(false);
    }

    setTouched(true);
    setLoading(true);

    const fetchInvitePromiseSuccess = ({ getInvites }) => {
      setError(getInvites.error || undefined);
      setResponse(getInvites.data);
      setLoading(false);
    };

    const fetchInvitePromiseError = (error) => {
      setError(error);
      setLoading(false);
    };

    const fetchInviteQuery = {
      query: fetchProjectInvitesQuery,
      variables: {
        input: {
          status: 'OPEN', // Filter only pending invites
          offset: (page * perPage) - perPage,
          limit: perPage
        }
      }
    };

    graphQLEditor
      .query(fetchInviteQuery)
      .catch(fetchInvitePromiseError)
      .then(fetchInvitePromiseSuccess);
  
  // eslint-disable-next-line
  }, [ projectId, page, perPage, shouldForceUpdate ]);

  return [
    touched,
    loading,
    error,
    response,
    setShouldForceUpdate
  ]
}

const InviteListContent = ({ touched, loading, error, response, onDelete, ...props }) => {
  if (!touched || loading) {
    return (<span>Carregando...</span>);
  }

  if (error) {
    return (<span>Falha ao carregar convites: {error.message || error.toString() }</span>);
  }

  if (response && response.count === 0) {
    return (<span>Nenhum convite pendente</span>);
  }

  return (
    <Container
      {...props}
      items={response.edges}
      onDelete={onDelete}
    />
  );
}

InviteListContent.defaultProps = {
  touched: false,
  loading: false,
  error: undefined,
  response: undefined,
  onDelete: () => {}
}

const InviteList = ({ projectId, ...props }) => {
  const [ page, setPage ] = useState(1);
  const perPage = 5;
  const [ touched, loading, error, response, setShouldForceUpdate ] = useFetchProjectInvites(projectId, page, perPage);

  const handleNextPage = useCallback(() => {
    setPage(page + 1);
  }, [ page ]);

  const handlePrevPage = useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [ page ]);

  const handleRefresh = () => {
    console.log('Refresh clicked!');
    setShouldForceUpdate(true);
  }

  const handleDelete = (invite) => {
    console.log('Handle delete invite', invite);
    setShouldForceUpdate(true);
  }

  const pageCount = (response && response.count) ? Math.ceil(response.count / perPage) : 1;

  return (
    <AsideContent id="aside-content">
      <Box>
        <h3>Convites pendentes</h3>
        <span>Usuários convidados para este projeto</span><br/><br/>
        <AddInviteForm onSuccess={handleRefresh} />
        <InviteListContent
          touched={touched}
          loading={loading}
          error={error}
          response={response}
          onDelete={handleDelete}
        />
        <br />
        <button onClick={handlePrevPage} disabled={!response || page === 1}>Anterior</button>
        <span> { page } / { pageCount } </span>
        <button onClick={handleNextPage} disabled={!response || response.count === 0 || page === pageCount}>Próximo</button>
      </Box>
    </AsideContent>
  )
}

export default InviteList;