import styled from 'styled-components';

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & span.username {
    margin-left: 7px;
  }
`;

export {
  AvatarContainer
}