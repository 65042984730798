import React from 'react';

import { Container, Body, Title } from '../../../components/Layout/Page';
import Layout from '../../../components/DefaultLayout';
import withRequiredProject from '../../../components/withRequiredProject';
import useCurrentProjectId from '../../../hooks/useCurrentProjectId';

const ProjectDashboardPage = (props) => {
  const projectId = useCurrentProjectId();
  return (
    <Layout>
      <Container>
        <Body>
          <Title title="Dashboard" />
          <h2>Projeto "{props?.project?.name || ''}"</h2>
          <p>Navegue pelo menu lateral para ver mais detalhes do projeto.</p>
          <p>Project: {projectId}</p>
        </Body>
      </Container>
    </Layout>
  )
}

export default withRequiredProject(ProjectDashboardPage);