import React from 'react';

// Components
import { ProjectGroup as MenuGroup } from './styles';
import MenuItem from './MenuItem';

const OpenProjectForm = ({ projects, selected, defaultProjectId, isToSaveAsDefaultProject, onChange, onSubmit, onToggleIsToSaveAsDefaultProject, ...props }) => {

  const handleIsToSaveAsDefaultProjectToggle = () => {
    onToggleIsToSaveAsDefaultProject(!isToSaveAsDefaultProject);
  }

  const handleItemClick = (item, e) => {
    if (onChange) {
      onChange(item, e);
    }
  }

  return (
    <div {...props}>
      <MenuGroup>
        {
          projects.map((item) => (
            <MenuItem
              key={item.id}
              name={item.name}
              checked={selected === item.id}
              onClick={handleItemClick.bind(this, item)}
              onChange={handleItemClick.bind(this, item)}
            />
          ))
        }
      </MenuGroup>

      <br />

      <p>
        <input
          id="saveAsDefaultProject"
          type="checkbox"
          checked={Boolean(isToSaveAsDefaultProject)}
          onChange={handleIsToSaveAsDefaultProjectToggle}
        />

        <label htmlFor="saveAsDefaultProject">
          Definir como principal (<small>Atualmente: {defaultProjectId}</small>)
        </label>
      </p>

      <br />
      <button onClick={onSubmit} disabled={!selected}>Abrir</button>
    </div>
  )
}

OpenProjectForm.defaultProps = {
  projects: [],
  selected: null,
  defaultProjectId: null,
  isToSaveAsDefaultProject: false,
  onChange: () => {},
  onSubmit: () => {},
  onToggleIsToSaveAsDefaultProject: () => {}
};

export default OpenProjectForm;