import React from 'react';

import Link from './components/MenuItem/Link';
import LinkProject from './components/MenuItem/LinkProject';
import LinkOpenProject from './components/MenuItem/LinkOpenProject';

export default [
  {
    context: 'user',
    component: (<Link icon="home" text="Dashboard" href="/" />)
  },
  {
    context: 'user',
    component: (<Link icon="account_box" text="Minha Conta" href="/me" />)
  },
  {
    context: 'user',
    component: (<LinkOpenProject />)
  },
  {
    context: 'project',
    component: (<LinkProject icon="map" text="Mapa" href="/map" />)
  },
  {
    context: 'project',
    component: (<LinkProject icon="room" text="Serviços" href="/services" />)
  },
  {
    context: 'project',
    component: (<LinkProject icon="backup" text="Arquivos" href="/files" />)
  },
  {
    context: 'project',
    component: (<LinkProject icon="extension" text="Configurações" href="/settings" />)
  },
  {
    context: 'project',
    component: (<LinkProject icon="cached" text="Sincronizar dados" href="/sync" />)
  },
  {
    context: 'project',
    component: (<LinkProject icon="people" text="Membros & Convites" href="/members" />)
  }
]