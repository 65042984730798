import { BrowserStorage, ScopedCredentialStorage } from '../library/credential-store';

const storage = new ScopedCredentialStorage({
  scope: 'esl-dashboard',
  storage: new BrowserStorage()
});

if (process.env.REACT_APP_ENV !== 'production') {
  window.browserStorage = storage;
}

export default storage;