import { CredentialManager } from '../library/credential-store';
import storage from './browser-storage';

const manager = new CredentialManager({
  storage: storage
});

if (process.env.REACT_APP_ENV !== 'production') {
  window.credentials = manager;
}

export default manager;