import EventEmitter from 'events';
import { getUser, exchangeRefreshToken, normalizeUserAttributes } from '../utils/cognito';
import credentialManager from '../shared/credential-manager';
import storage from '../shared/storage';

const REFRESH_TOKEN_TTL = 15 * 24 * 60 * 60 * 1000; // 15 days
const ACCESS_TOKEN_TTL = (60 * 60 * 1000) - 60000; //  59 min

const fetchUserTokens = ({ refreshToken }) => {
  return new Promise((resolve, reject) => {
    const getAccessTokenPromise = exchangeRefreshToken(refreshToken);

    getAccessTokenPromise.then((data) => {
      resolve({
        accessToken: data.AuthenticationResult.AccessToken,
        idToken: data.AuthenticationResult.IdToken
      })
    });

    getAccessTokenPromise.catch((err) => {
      reject(err);
    });
  });
}

const fetchUserProfile = ({ accessToken }) => {
  return new Promise((resolve, reject) => {
    const getUserPromise = getUser(accessToken);

    getUserPromise.then((data) => {
      var parsedAttributes = normalizeUserAttributes(data.UserAttributes);
      resolve({ ...parsedAttributes });
    });
  
    getUserPromise.catch((err) => {
      reject(err);
    });
  });
}

const getUserKey = (userId, key) => {
  return `user.${userId}.${key}`;
}

class Authentication {
  events = null;

  constructor (params = {}) {
    this.events = new EventEmitter();

    if (process.env.REACT_APP_DEBUG === 'on') {
      this.events.on('data', console.log);
    }

    this.events.on('error', (e) => {
      console.log(e);

      if (e.type === 'no_refresh_token') {
        // TODO: No available refresh token
        this.events.emit('error', { type: 'connection_lost', retryable: false, reason: 'no_refresh_token' });
      }

      if (e.type === 'refresh_token_expired' || e.type === 'invalid_refresh_token') { // invalid_refresh_token
        // TODO: Logout user
        this.events.emit('error', { type: 'connection_lost', retryable: false, reason: e.type });
      }

      if (e.type === 'access_token_expired') {
        // TODO: Retry?
        this.events.emit('error', { type: 'connection_lost', retryable: true, reason: 'access_token_expired' });
      }
    });
  }

  getCurrentUser = () => {
    return storage.getItem('currentUserId');
  }

  getCurrentUserRefreshToken = () => {
    var userId = this.getCurrentUser();

    if (!userId) {
      return null;
    }

    return storage.getItem(getUserKey(userId, 'refreshToken'));;
  }

  setCurrentUser = (userId) => {
    this.events.emit('data', { name: 'current_user', value: userId });
    storage.setItem('currentUserId', userId, null);
  }

  setRefreshToken = (refreshToken) => {
    const userId = this.getCurrentUser();
    storage.setItem(getUserKey(userId, 'refreshToken'), refreshToken, REFRESH_TOKEN_TTL);
    this.events.emit('data', { name: 'refresh_token', userId, value: refreshToken });
  }

  setUserSession = (userId, refreshToken) => {
    this.setCurrentUser(userId);
    this.setRefreshToken(refreshToken);
  }

  asyncGetAccessToken = (force = false) => {
    return new Promise(async (resolve, reject) => {
      var userId = this.getCurrentUser();

      if (!userId) {
        let notAuthenticatedError = new Error('not_authenticated');
        this.events.emit('error', { type: 'not_authenticated', error: notAuthenticatedError });
        reject(notAuthenticatedError);
        return;
      }

      var accessToken = storage.getItem(getUserKey(userId, 'accessToken'));
      var idToken = storage.getItem(getUserKey(userId, 'idToken'));
      var isToSaveTokens = false;
      var refreshTokenError = null;

      if (!accessToken || force) {
        var refreshToken = storage.getItem(getUserKey(userId, 'refreshToken'));

        if (!refreshToken) {
          refreshTokenError = new Error('no_refresh_token');
        }

        if (!refreshTokenError) {
          try {
            var tokens = await fetchUserTokens({ refreshToken });

            isToSaveTokens = true;
            accessToken = tokens.accessToken;
            idToken = tokens.idToken;

          } catch (e) {
            refreshTokenError = e;
            isToSaveTokens = false;
          }
        }

        if (isToSaveTokens && !refreshTokenError) {
          storage.setItem(getUserKey(userId, 'accessToken'), accessToken, ACCESS_TOKEN_TTL);
          storage.setItem(getUserKey(userId, 'idToken'), idToken, ACCESS_TOKEN_TTL);

          this.events.emit('data', { name: 'access_token', value: accessToken });
          this.events.emit('data', { name: 'id_token', value: idToken });
        }
      }

      if (refreshTokenError) {
        this.events.emit('error', { type: refreshTokenError.message === 'no_refresh_token' ? 'no_refresh_token' : 'invalid_refresh_token', error: refreshTokenError, userId });
        reject(refreshTokenError);
        return;
      }

      resolve(accessToken);
    });
  }

  asyncGetIdToken = (force = false) => {
    return new Promise(async (resolve, reject) => {
      var userId = this.getCurrentUser();

      if (!userId) {
        let notAuthenticatedError = new Error('not_authenticated');
        this.events.emit('error', { type: 'not_authenticated', error: notAuthenticatedError });
        reject(notAuthenticatedError);
        return;
      }

      var accessToken = storage.getItem(getUserKey(userId, 'accessToken'));
      var idToken = storage.getItem(getUserKey(userId, 'idToken'));
      var isToSaveTokens = false;
      var refreshTokenError = null;

      if (!idToken || force) {
        var refreshToken = storage.getItem(getUserKey(userId, 'refreshToken'));

        if (!refreshToken) {
          refreshTokenError = new Error('no_refresh_token');
        }

        if (!refreshTokenError) {
          try {
            var tokens = await fetchUserTokens({ refreshToken });

            isToSaveTokens = true;
            idToken = tokens.idToken;
          } catch (e) {
            refreshTokenError = e;
            isToSaveTokens = false;
          }
        }

        if (isToSaveTokens && !refreshTokenError) {
          storage.setItem(getUserKey(userId, 'accessToken'), accessToken, ACCESS_TOKEN_TTL);
          storage.setItem(getUserKey(userId, 'idToken'), idToken, ACCESS_TOKEN_TTL);

          this.events.emit('data', { name: 'access_token', value: accessToken });
          this.events.emit('data', { name: 'id_token', value: idToken });
        }
      }

      if (refreshTokenError) {
        this.events.emit('error', { type: refreshTokenError.message === 'no_refresh_token' ? 'no_refresh_token' : 'invalid_refresh_token', error: refreshTokenError, userId });
        reject(refreshTokenError);
        return;
      }

      resolve(idToken);
    });
  }

  asyncGetUserAttributes = (force = false) => {
    return new Promise(async (resolve, reject) => {
      var userId = this.getCurrentUser();

      if (!userId) {
        let notAuthenticatedError = new Error('not_authenticated');
        this.events.emit('error', { type: 'not_authenticated', error: notAuthenticatedError });
        reject(notAuthenticatedError);
        return;
      }

      var userAttributes = storage.getItem(getUserKey(userId, 'profile'));

      if (userAttributes) {
        userAttributes = JSON.parse(userAttributes);
      }

      var accessToken = null;
      var accessTokenException = undefined;
      var fetchUserProfileException = undefined;
      var overwriteUserAttributes = false;

      if (!userAttributes || force) {
        try {
          accessToken = await this.asyncGetAccessToken(force);
        } catch (e) {
          accessTokenException = e;
        }

        if (!accessTokenException) {
          try {
            userAttributes = await fetchUserProfile({ accessToken });
            overwriteUserAttributes = true;
          } catch (ex) {
            fetchUserProfileException = ex;
          }
        }
      }

      if (overwriteUserAttributes && userAttributes) {
        storage.setItem(getUserKey(userId, 'profile'), JSON.stringify(userAttributes), ACCESS_TOKEN_TTL);
        this.events.emit('data', { name: 'profile', userId, value: userAttributes });
      }

      if (fetchUserProfileException) {
        /*
        if (e.code === 'NotAuthorized')
        console.log({ fetchUserProfileException });
        console.log(fetchUserProfileException.code);
        //this.events.emit('error', { type: 'access_token_expired', userId });
        */

        this.events.emit('error', { type: 'fetch_user_profile_err', error: fetchUserProfileException, userId });
        reject(fetchUserProfileException);
        return;
      }

      if (accessTokenException) {
        this.events.emit('error', { type: 'get_access_token_err', error: accessTokenException, userId });
        reject(accessTokenException);
        return;
      }

      resolve(userAttributes);
    });
  }

  asyncStartFromRefreshToken = (refreshToken) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { accessToken, idToken } = await fetchUserTokens({ refreshToken });
        const profile = await fetchUserProfile({ accessToken });

        this.setCurrentUser(profile.sub);
        this.setRefreshToken(refreshToken);
        resolve({ userId: profile.sub, accessToken, idToken, refreshToken });
      } catch (e) {
        reject(e);
      }
    });
  }
}

export default Authentication;