import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f1f1f1;
  padding: 14px;
  margin: 14px 0px;
  border-radius: 4px;
  display: flex;
  width: calc(100% - 28px);
  max-width: 980px;
  flex-direction: column;

  &.has-error {
    background-color: #ffd1d1;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
`;

export const ButtonHolder = styled.div`
`;

export const FieldHolder = styled.div`
  width: 100%;
  flex-grow: 1;
  margin-right: 14px;
`;
