import React, { useState, useEffect } from 'react';

// Components
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InviteIcon from '@material-ui/icons/Mail';
import DeleteIcon from '@material-ui/icons/Close';
import ResendIcon from '@material-ui/icons/Refresh';

// Styles
import { StyledContainer, StyledHeader } from './styles';

// i18n
import i18nPermission from '../../i18n/permissions';
import i18nUsername from '../../i18n/username';

import useMutation from '../../hooks/useMutation';

const GRAPHQL_RESEND_INVITE_MUTATION = `
  mutation resendInvitation ($input: ResendInvitationInput!) {
    resendInvitation (input: $input) {
      error
      data
    }
  }
`;

const useResendInviteMutation = (inviteId) => {
  const [ shouldExecute, setShouldExecute ] = useState(false);
  const { error, response, isSending, sendMutation } = useMutation(GRAPHQL_RESEND_INVITE_MUTATION);
  const wasReplied = error || response;

  useEffect(() => {
    if (shouldExecute === true && !isSending) {
      setShouldExecute(false);
      sendMutation({
        input: {
          id: inviteId
        }
      });
    }
  }, [ shouldExecute ]);

  return {
    // States
    inviteId,
    isSending,
    error,
    response,
    shouldExecute,

    // Computed
    wasReplied: Boolean(wasReplied),
    
    // Handlers
    setShouldExecute
  }
}

const useDeleteInviteMutation = (inviteId) => {
  const [ isSending, setIsSending ] = useState(false);
  const [ isToDelete, setIsToDelete ] = useState(false);
  const [ error, setError ] = useState(undefined);
  const [ response, setResponse ] = useState(undefined);

  useEffect(() => {
    if (!inviteId) {
      setResponse(undefined);
      setError(undefined);
      setIsSending(false);
      setIsToDelete(false);
    }
  }, [ inviteId ]);

  if (isToDelete === true && inviteId && !isSending) {
    console.log('Do something good');
    setIsToDelete(false);
    setIsSending(true);
    setError(undefined);
    setResponse(undefined);

    setTimeout(() => {
      setResponse('OK');
      setIsSending(false);
    }, 2000);
  }

  return {
    inviteId,
    isSending,
    isToDelete,
    error,
    response,

    setIsToDelete
  }
}

const InviteCardContentRequester = ({ requester }) => {
  if (requester.error) {
    return (
      <small>
        <span>convidado por </span>Usuário não encontrado
      </small>
    );
  }

  return (
    <small>
      <span>convidado por </span><Link href="#">{i18nUsername(requester.data.user)}</Link>
    </small>
  );
}

const InviteCard = ({ invitation, onDelete, ...props }) => {
  const resendQuery = useResendInviteMutation(invitation.id);
  const deleteQuery = useDeleteInviteMutation(invitation.id);

  /**
   * Handle resend
   * @param {Event} e 
   */
  const handleResend = (e) => {
    if (!resendQuery.isSending) {
      resendQuery.setShouldExecute(true);
    }
  }

  /**
   * Handle delete
   * @param {Event} e 
   */
  const handleDelete = (e) => {
    if (!deleteQuery.isSending) {
      deleteQuery.setIsToDelete(true);
    }
  }

  const _renderResendButtonMessage = () => {
    if (resendQuery.isSending) {
      return 'Enviando email...';
    }

    if (!resendQuery.isSending && resendQuery.error) {
      return `Falha ao enviar email`;
    }

    return 'Enviar novamente';
  }

  if (deleteQuery.wasReplied && deleteQuery.response) {
    if (onDelete) {
      onDelete(deleteQuery.response);
    }
  }

  const isSubmitting = deleteQuery.isSending || resendQuery.isSending;

  return (
    <StyledContainer {...props} style={{opacity: deleteQuery.isSending ? 0.4 : 1}}>
      <div className="invite-body">
        <StyledHeader>
          <InviteIcon className="invite-icon" />
          <span className="invite-address" title={`Email do usuário convidado: ${invitation.email}`}>
            { invitation.email || '???' }
          </span>
        </StyledHeader>
        <small>
          <span className="invite-permission" title={`Nível de permissão do usuário: ${i18nPermission(invitation.permission)}`}>
            Permissão: {i18nPermission(invitation.permission)}
          </span>
        </small>
        <br/>
        <InviteCardContentRequester requester={invitation.requester} />
        <Button variant="text" style={{marginTop: '8px'}} onClick={handleResend} disabled={isSubmitting}>
          <ResendIcon style={{marginRight: '8px'}} /> { _renderResendButtonMessage() }
        </Button>
      </div>
      <div className="invite-controls">
        <IconButton size="small" onClick={handleDelete} disabled={isSubmitting}>
          <DeleteIcon />
        </IconButton>
      </div>
    </StyledContainer>
  )
};

InviteCard.defaultProps = {
  onDelete: () => {}
};

export default InviteCard;