
const accessKey = (userId, key) => {
  return `u${userId}.${key}`;
}

function setItem (userId, key, value) {
  localStorage.setItem(accessKey(userId, key), value);
}

function getItem (userId, key) {
  return localStorage.getItem(accessKey(userId, key));
}

function removeItem (userId, key) {
  return localStorage.removeItem(accessKey(userId, key));
}

const userPreferences = {
  setItem: setItem,
  getItem: getItem,
  removeItem: removeItem,
  user: function (userId) {
    return {
      setItem: function (key, value) {
        setItem(userId, key, value);
      },
      getItem: function (key) {
        return getItem(userId, key);
      }
    }
  }
};

export default userPreferences;
