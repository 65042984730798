import { useState, useEffect } from 'react';
import userPreferences from '../shared/user-preferences';

const usePreference = (userId, key, defaultValue = null) => {
  const [ value, setValue ] = useState(defaultValue);

  useEffect(() => {
    if (!userId && !key) return;

    var storedValue = userPreferences.getItem(userId, key);
    setValue(storedValue || defaultValue);
  
  // eslint-disable-next-line
  }, [ userId, key ]);

  const saveItem = (value) => {
    userPreferences.setItem(userId, key, value);
    setValue(value);
  }

  const removeItem = () => {
    userPreferences.removeItem(userId, key);
    setValue(defaultValue);
  }

  return [
    value,
    saveItem,
    removeItem
  ]
}

export default usePreference;