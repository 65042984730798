import { useState, useEffect } from 'react';
//import { Hub } from 'aws-amplify';

import authentication from '../shared/authentication';

const useCurrentUserId = () => {
  const [ currentUserId, setCurrentUserId ] = useState(undefined);

  useEffect(() => {
    /*
    if (session && session.profile) {
      setCurrentUser(session.profile.sub);
    }

    var handler = ({ payload }) => {
      if (payload.event === 'credential_updated' && payload.param === 'profile') {
        setCurrentUser(session.profile.sub);
      }
    }
    */

    setCurrentUserId(authentication.getCurrentUser());
    //Hub.listen('session', handler);

    return () => {
      //Hub.remove('session', handler);
    }
  }, []);

  return currentUserId;
}

export default useCurrentUserId;