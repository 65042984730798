import React from 'react';
import { styled } from '@material-ui/styles';

import DashboardAppBar from './AppBar';

const DashboardContentRoot = styled('div')({
  width: '100%',
  height: 'calc(100vh - 64px)',
  overflowY: 'scroll'
});

class DashboardContent extends React.Component {
  render = () => {
    return (
      <div style={{ width: '100%', overflowX: 'hidden' }}>
        <DashboardAppBar/>
        <DashboardContentRoot id="content-root">
          { this.props.children }
        </DashboardContentRoot>
      </div>
    )
  }
}

export default DashboardContent;