import React from 'react';
import Button from '@material-ui/core/Button';
import MailIcon from '@material-ui/icons/Mail';
import PasswordIcon from '@material-ui/icons/VpnKey';

import Layout from '../../components/DefaultLayout';
import InviteList from '../../components/UserInviteList';
import { Container, Title, Body } from '../../components/Layout/Page';
import withCurrentUser from '../../components/withCurrentUser';

import {
  ButtonIcon,
  ButtonsHolder,
  UserDetailHolder,
  UserDetailInfo
} from './styles';

const MembersPage = ({ user }) => {
  var invites = [];

  function invitesRenderer () {
    if (invites.length === 0) {
      return (<span>Você não tem nenhum convite ainda.</span>)
    }

    return (<InviteList items={invites} />);
  }

  return (
    <Layout>
      <Container>
        <Title title={'Minha Conta'}></Title>
        <Body>
          <h3>Detalhes</h3>
          <UserDetailHolder>
            <UserDetailInfo>
              <span>ID: </span>
              <span>{user.info.sub}</span>
            </UserDetailInfo>
            <UserDetailInfo>
              <span>Nome: </span>
              <span>{user.info.name}</span>
            </UserDetailInfo>
            <UserDetailInfo>
              <span>Email: </span>
              <span>{user.info.email}</span>
            </UserDetailInfo>
          </UserDetailHolder>
          <br/>

          <ButtonsHolder>
            <Button variant="outlined" color="primary">
              <ButtonIcon>
                <MailIcon />
              </ButtonIcon>
              Alterar email
            </Button>

            <Button variant="outlined" color="primary">
              <ButtonIcon>
                <PasswordIcon />
              </ButtonIcon>
              Alterar senha
            </Button>
          </ButtonsHolder>

          <h3>Meus convites</h3>
          { invitesRenderer() }
        </Body>
      </Container>
    </Layout>
  )
}

export default withCurrentUser(MembersPage);
