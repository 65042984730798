import createSagaMiddleware from 'redux-saga';
import {
  createStore,
  applyMiddleware
} from 'redux';

import reducers from './reducers';
import sagaRoot from './sagas';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducers,
  applyMiddleware(sagaMiddleware),
);

sagaMiddleware.run(sagaRoot);
export default store;