import React from 'react'
import { ProjectItemContainer } from './styles';

const MenuItem = (props) => {
  return (
    <ProjectItemContainer { ...props }>
      <input type="radio" name="project" checked={props.checked} onChange={props.onChange} />
      <p>{props.name}</p>
    </ProjectItemContainer>
  )
}

export default MenuItem;