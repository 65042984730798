import { combineReducers } from 'redux';

import usersReducer from './users';
import projectsReducer from './projects';
import mapPageReducer from './mapPage';
import userPageReducer from './userPage';
import membersPageReducer from './membersPage';
import settingsPageReducer from './settingsPage';

import appReducer from './app';

export default combineReducers({
  app: appReducer,
  users: usersReducer,
  projects: projectsReducer,
  mapPage: mapPageReducer,
  userPage: userPageReducer,
  membersPage: membersPageReducer,
  settingsPage: settingsPageReducer
});
