
/**
 * Return inviter name
 * @param {Object|null} user 
 */
function getUsername (user) {
  if (!user) {
    return 'Conta apagada';
  }

  if (!user.name) {
    return 'Sem nome';
  }

  return user.name;
}

export default getUsername;