import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

// Components
import { Form, Dialog } from '../../components/OpenProjectDialog';

// Hooks
import useCurrentUserId from '../../hooks/useCurrentUserId';
import useToken from '../../hooks/useToken';
import usePreference from '../../hooks/usePreference';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api.esl.teewe.com.br';

const fetchProjects = async (accessToken) => {
  const requestParams = {
    url: `${API_ENDPOINT}/graphql/user`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    json: true,
    data: {
      query: `
        query getUserProjects {
          getProjects {
            error {
              type
            }
            data {
              count
              edges {
                permission
                project {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables: {}
    }
  };

  const response = await axios.request(requestParams);
  const { getProjects } = response.data.data;
  return getProjects;
}

const useFetchUserProjects = (userId) => {
  const [ touched, setTouched ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(undefined);
  const [ response, setResponse ] = useState(undefined);
  const [ forceUpdate, setForceUpdate ] = useState(false);
  const { idToken } = useToken(); // TODO: Change to use access token later

  useEffect(() => {
    if (!userId || !idToken) {
      return;
    }

    setLoading(true);
    setTouched(true);
    setError(undefined);
    setResponse(undefined);

    const fetchProjectsPs = fetchProjects(idToken);

    fetchProjectsPs.finally(() => {
      setLoading(false);
      setForceUpdate(false);
    });

    fetchProjectsPs.then((response) => {
      if (response.error) {
        setError(response.error);
      } else {
        setResponse(response.data);
      }
    });

    fetchProjectsPs.catch((err) => {
      setError(err);
    });

  }, [ userId, idToken, forceUpdate ]);

  return {
    touched,
    loading,
    error,
    response,
    forceUpdate: () => { setForceUpdate(true) }
  }
}

const parseProjectItem = (item) => {
  return {
    id: item.project.id,
    name: item.project.name,
    permission: item.permission
  }
}

const OpenProjectPageHook = (props) => {
  const history = useHistory();
  const userId = useCurrentUserId();
  const projects = useFetchUserProjects(userId);

  const [ selected, setSelected ] = useState(undefined);  
  const [ startupProjectId, setStartupProjectId ] = usePreference(userId, 'startupProjectId', null);  // Save in user preferences...
  const [ isToSaveAsDefaultProject, setIsSaveAsStartupProjectEnabled ] = useState(false);

  const handleIsSaveAsStartupProjectEnabledChange = (isEnabled) => {
    setIsSaveAsStartupProjectEnabled(isEnabled);
  }

  const handleChange = (item) => {
    setSelected(item.id);
  }

  const handleSubmit = () => {
    var choosen = selected;

    if (!choosen) {
      choosen = startupProjectId;
    }

    if (!choosen) return;

    if (isToSaveAsDefaultProject) {
      setStartupProjectId(choosen);
    }

    history.push(`/project/${choosen}`);
  }

  if (!projects.touched || projects.loading) {
    return (
      <Dialog {...props}>
        <p>Carregando projetos...</p>
      </Dialog>
    );
  }

  if (projects.error) {
    return (
      <Dialog {...props}>
        <p>Falha ao carregar projetos: {projects.error}</p>
      </Dialog>
    );
  }

  if (projects.response && projects.response.count === 0) {
    return (
      <Dialog {...props}>
        <p>Você ainda não está participando de nenhum projeto.</p>
      </Dialog>
    );
  }

  return (
    <Dialog {...props}>
      <h2>Escolha um projeto...</h2>
      <p>Você tem acesso a mais de um projeto. por favor selecione o projeto que você deseja visualizar.</p>
      <hr/>

      <Form
        selected={selected || startupProjectId}
        defaultProjectId={startupProjectId}
        isToSaveAsDefaultProject={isToSaveAsDefaultProject}
        projects={projects?.response?.edges.map(parseProjectItem)}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onToggleIsToSaveAsDefaultProject={handleIsSaveAsStartupProjectEnabledChange}
      />
    </Dialog>
  )
}

export default OpenProjectPageHook;