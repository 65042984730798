import React from 'react';
import Form from './InviteBox';
import withApi from '../withApi';

class AddInviteForm extends React.PureComponent {

  constructor (props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      email: ''
    };
  }

  /**
   * Handle email change
   * @param {String} emailAddress
   */
  handleChange = (emailAddress) => {
    this.setState({
      email: emailAddress
    });
  }

  /**
   * Handle form submit
   * @param {Event} e
   */
  handleSubmit = () => {
    const { apiClient } = this.props;

    this.setState({
      loading: true,
      error: null
    });

    var createInviteParams = {
      email: this.state.email.toString().toLowerCase(),
      permission: 'READ'
    };

    apiClient.post('/graphql/editor', {
      query: `
        mutation createInvitation ($input: CreateInvitationInput!) {
          createInvitation (input: $input) {
            error
            data {
              id
              email
              permission
              requester {
                error
                data {
                  user {
                    id
                    name
                  }
                }
              }
              createdAt
            }
          }
        }
      `,
      variables: {
        input: createInviteParams
      }
    })
    .then((response) => {
      const { createInvitation } = response.data.data;

      if (createInvitation.error) {
        return this._handleError(createInvitation.error);
      }

      this._handleSuccess(createInvitation.data);
    })
    .catch((err) => {
      this._handleError(err);
    });
  }

  _handleSuccess = (invitation) => {
    this.setState({
      loading: false,
      error: null
    });

    this.dispatchEventOnSuccess(invitation);
  }

  _handleError = (error) => {
    this.setState({
      loading: false,
      error: error.toString()
    });

    this.dispatchEventOnError(error);
  }

  /**
   * Dispatch "onSuccess" event
   * @param {Object} invitation
   */
  dispatchEventOnSuccess = (invitation) => {
    if (this.props.onSuccess) {
      this.props.onSuccess(invitation);
    }
  }

  /**
   * Dispatch "onError" event
   * @param {Object} error
   */
  dispatchEventOnError = (error) => {
    if (this.props.onError) {
      this.props.onError(error);
    }
  }

  render () {
    return (
      <Form
        value={this.state.email || ''}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        sending={this.state.loading}
        error={this.state.error}
      />
    )
  }
}

export default withApi(AddInviteForm);
