import React from 'react';

import Link from '../Link';
import withCurrentProject from '../../withCurrentProject';

const LinkOpenProject = (props) => {
  var text = 'Trocar projeto';

  if (!props.project || !props.project.id) {
    text = 'Selecionar projeto';
  }

  return (<Link icon="tab" text={text} href="/open" />)
};

export default withCurrentProject(LinkOpenProject);