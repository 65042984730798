import React from 'react';
import { Container, Box } from './styles';

const OpenProjectDialog = ({ children, ...props }) => {
  return (
    <Container {...props}>
      <Box>
        { children }
      </Box>
    </Container>
  )
}

export default OpenProjectDialog;