import React from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import CheckIcon from '@material-ui/icons/Check';

const StyledPaper = styled(Paper)`
  padding: 7px 14px;
  margin: 4px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const ButtonsHolder = styled.div`
  margin-left: 16px;

  & button {
    margin-right: 6px;
  }

  & button:last-child {
    margin-right: 0px;
  }
`

const InviteItem = (props) => {
  return (
    <StyledPaper>
      <span>
        <Link href={`/#/users/${props.inviter.id}`}>{props.inviter.name}</Link>
        <span> convidou você participar do projeto </span>
        <Link href={`#project/${props.project.id}/info`}>{props.project.name}</Link>
      </span>
      <ButtonsHolder>
        <Button variant="contained" color="primary">
          <CheckIcon />
          Aceitar
        </Button>
        <Button variant="outlined" color="secondary">Excluir</Button>
      </ButtonsHolder>
    </StyledPaper>
  )
}

export default InviteItem;