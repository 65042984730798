import styled from 'styled-components';

export const ProjectItemContainer = styled.div`
  width: 100%;
  min-height: 45px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 7px;
  color: #333;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 4px;
  box-sizing: border-box;
`;

export const ProjectGroup = styled.div`

`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Box = styled.div`
  width: 90%;
  max-width: 600px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 15px;
  overflow: hidden;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.2);
  border-radius: 2px;
`;

