var initialState = {
  byId: {},
  currentId: null
};

var actions = {
  'PROJECTS.SET_CURRENT_ID': function (state, action) {
    const { value } = action;

    return {
      ...state,
      currentId: value
    };
  }
};

export default function (state = initialState, action) {
  if (actions[action.type]) {
    return actions[action.type](state, action);
  }

  return state;
}
