import React from 'react';
import { useSelector } from 'react-redux';

import AppRouter from './Router';
import RootProvider from './components/RootProvider';
import UserLoader from './components/UserLoader';

//import ProjectLoader from  './components/ProjectLoader';

const Bootstrap = () => {
  const appState = useSelector((state) => state.app);

  if (!appState.touched) {
    // Do something if not initialized yet...
  }

  if (appState.error) {
    return (
      <>
        <span>Oops! Ocorreu alguma falha durante a inicialização</span>
        <small>Mensagem: {appState.error.message || appState.error.toString()}</small>
      </>
    )
  }

  /*
  if (!appState.touched || !appState.loaded) {
    return (
      <span>Inicializando aplicação...</span>
    )
  }
  */

  return (
    <UserLoader>
      <RootProvider>
        <AppRouter />
      </RootProvider>
    </UserLoader>
  )
}

export default Bootstrap;