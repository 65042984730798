import React from 'react';
//import store from '../store';
//import getCurrentUser from '../store/selectors/getCurrentUser';
//import { connect } from 'react-redux';
//import session from '../shared/session';
//import authentication from '../shared/authentication';

import useCurrentUser from '../hooks/useCurrentUser';
import useCurrentUserId from '../hooks/useCurrentUserId';

/*
const withCurrentUser = (Component) => {
  return connect((state) => {
    return {
      user: getCurrentUser(state)
    }
  })((props) => {
    return (<Component {...props} />)
  })
};
*/

const withCurrentUser = (Component) => {
  return (props) => {
    const userId = useCurrentUserId();
    const userAttributes = useCurrentUser();

    var user = {
      id: userId,
      info: { ...userAttributes }
    };

    return (<Component user={user} {...props} />)
  }
}

export default withCurrentUser;
