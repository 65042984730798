import React, { useEffect, useState } from 'react';
import Container from '../MemberList';
import { Box, MainContent } from './styles';

import useGraphQLEditorAPI from '../../hooks/useGraphQLEditorAPI';

const fetchProjectMembersQuery =  `
  query getMembers ($input: GetMembersInput!) {
    getMembers(input: $input) {
      error
      data {
        count
        edges {
          permission
          memberId
          user {
            error
            data {
              id
              name
              avatarUrl
            }
          }
        }
      }
    }
  }
`;

const useFetchProjectMembers = (projectId, page = 1, perPage = 10) => {
  const [ touched, setTouched ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(undefined);
  const [ response, setResponse ] = useState(undefined);
  const graphQLEditor = useGraphQLEditorAPI(projectId);

  useEffect(() => {
    setTouched(true);
    setLoading(true);

    const fetchMembersQuery = {
      query: fetchProjectMembersQuery,
      variables: {
        input: {
          offset: (page * perPage) - perPage,
          limit: perPage
        }
      }
    };

    const fetchMembersData = ({ getMembers }) => {
      setError(getMembers.error || undefined);
      setResponse(getMembers.data);
      setLoading(false);
    };

    const fetchMembersError = (error) => {
      setError(error);
      setLoading(false);
    };

    graphQLEditor
      .query(fetchMembersQuery)
      .catch(fetchMembersError)
      .then(fetchMembersData);

  // eslint-disable-next-line
  }, [ projectId, page, perPage ]);

  return [
    touched,
    loading,
    error,
    response
  ]
}

const MemberListContent = ({ projectId, touched, loading, error, response, onChange, ...props }) => {
  if (!touched || loading) {
    return (<p>Carregando...</p>);
  }

  if (error) {
    return (<p>Falha ao carregar membros: {error}</p>);
  }

  return (
    <Container
      projectId={projectId}
      rows={response.edges}
      onChange={onChange}
      {...props}
    />
  );
}

const MemberList = ({ projectId, ...props }) => {
  const [ page, setPage ] = useState(1);
  const perPage = 10;
  const [ touched, loading, error, response ] = useFetchProjectMembers(projectId, page, perPage);

  const handleNextPage = () => {
    setPage(page + 1);
  }

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  }

  const handleChange = (items) => {
    // this.setState({ items });
    // Force update?
  }

  const pageCount = (response && response.count) ? Math.ceil(response.count / perPage) : 1;

  return (
    <MainContent id="main-content">
      <Box>
        <h3>Membros ativos</h3>
        <span>Usuários que estão participando deste projeto</span><br/><br/>
        <div style={{height: '100%'}}>
          <MemberListContent
            projectId={projectId}
            touched={touched}
            loading={loading}
            error={error}
            response={response}
            onChange={handleChange}
          />
          <br />
          <button onClick={handlePrevPage} disabled={!response || page === 1}>Anterior</button>
          <span> { page } / { pageCount } </span>
          <button onClick={handleNextPage} disabled={!response || response.count === 0 || page === pageCount}>Próximo</button>
        </div>
      </Box>
    </MainContent>
  )
}

export default MemberList;