import React from 'react';
import { Route } from 'react-router-dom';

import withSignedUserOnly from '../../components/withSignedUserOnly';

const ProtectedRoute = (props) => {
  const ProtectedComponent = withSignedUserOnly(props.component);
  return (<Route {...props} component={ProtectedComponent} />);
}

export default ProtectedRoute;