import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

// Providers
import ProjectLoader from  './components/ProjectLoader';
import ProtectedRoute from './components/ProtectedRoute';

/*
// Root pages
const DashboardPage = lazy(() => { import('./pages/Dashboard') });
const UserPage = lazy(() => { import('./pages/User') });
const Error4xxPage = lazy(() => { import('./pages/Error4xx') });
const OpenProjectPage = lazy(() => { import('./pages/SelectProject') });
const SandBoxPage = lazy(() => { import('./pages/Sandbox') });
const OAuthCallbackPage = lazy(() => { import('./pages/OAuthCallback') });
const AboutPage = lazy(() => { import('./pages/About') });

// Project pages
const LandingPage = lazy(() => { import('./pages/Project/Dashboard') });
const MapPage = lazy(() => { import('./pages/Project/Map') });
const FilesPage = lazy(() => { import('./pages/Project/Files') });
const ServicesPage = lazy(() => { import('./pages/Project/Markers') });
const MembersPage = lazy(() => { import('./pages/Project/Members') });
const SettingsPage = lazy(() => { import('./pages/Project/Settings') });
const SyncPage = lazy(() => { import('./pages/Project/Sync') });
*/

// Root pages
import DashboardPage from './pages/Dashboard';
import UserPage from './pages/User';
import AboutPage from './pages/About';
import Error4xxPage from './pages/Error4xx';
import SandBoxPage from './pages/Sandbox';
import OpenProjectPage from './pages/SelectProject';
import OAuthCallbackPage from './pages/OAuthCallback';

// Project pages
import MapPage from './pages/Project/Map';
import SyncPage from './pages/Project/Sync';
import FilesPage from './pages/Project/Files';
import MembersPage from './pages/Project/Members';
import ServicesPage from './pages/Project/Markers';
import SettingsPage from './pages/Project/Settings';
import LandingPage from './pages/Project/Dashboard';

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <ProtectedRoute path="/" exact component={DashboardPage} />
        <ProtectedRoute path="/project/:projectId">
          <ProjectLoader>
            <Route path="/project/:projectId" exact component={LandingPage} />
            <Route path="/project/:projectId/map" exact component={MapPage} />
            <Route path="/project/:projectId/members" exact component={MembersPage} />
            <Route path="/project/:projectId/services" exact component={ServicesPage} />
            <Route path="/project/:projectId/services/:id" exact component={ServicesPage} />
            <Route path="/project/:projectId/settings" exact component={SettingsPage} />
            <Route path="/project/:projectId/sync" exact component={SyncPage} />
            <Route path="/project/:projectId/files" exact component={FilesPage} />
          </ProjectLoader>
        </ProtectedRoute>
        <ProtectedRoute path="/me" exact component={UserPage} />
        <ProtectedRoute path="/sync" exact component={Error4xxPage} />
        <ProtectedRoute path="/open" exact component={OpenProjectPage} />
        <Route path="/oauth/callback" component={OAuthCallbackPage} />
        <Route path="/sandbox" component={SandBoxPage} />
        <Route path="/about" component={AboutPage} />
        <Route path="*" component={Error4xxPage} />
      </Switch>
    </Router>
  )
}

export default AppRouter;