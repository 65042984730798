import React, { useState } from 'react';
import axios from 'axios';

// Components
import Layout from '../../../components/DefaultLayout';
import { Container, Body, Title } from '../../../components/Layout/Page';
import Button from '@material-ui/core/Button';

// Hooks
import useCurrentProjectId from '../../../hooks/useCurrentProjectId';

const WRITER_SERVICE = 'http://api.esl.teewe.com.br:9002';
const ELASTIC_INSTALLER_SERVICE = 'http://api.esl.teewe.com.br:9007';
const DIGEST_SERVICE = 'http://api.esl.teewe.com.br:9009';

function ButtonHttpRequest (props) {
  const [ isSending, setSending ] = useState(false);
  const [ error, setError ] = useState(null);

  const handleClick = () => {
    setSending(true);
    
    var xhrOptions = {
      method: props.method,
      url: props.url
    };

    axios(xhrOptions)
      .then((data) => {
        if (props.onSuccess) {
          props.onSuccess(data);
        }
      })
      .catch((err) => {
        setError(err.message || err.toString());
      })
      .finally(() => {
        setSending(false);
      });
  }

  return (
    <div style={{width: '100%', marginTop: '8px'}}>
      <Button variant="contained" color="primary" onClick={handleClick} disabled={Boolean(isSending)}>
        { isSending ? props.texts.sending : props.texts.normal }
        { error && (<small>{'\u00A0'}(Erro: {error})</small>) }
      </Button>
    </div>
  )
}

const SyncProjectPage = (props) => {
  const projectId = useCurrentProjectId();

  return (
    <Layout>
      <Container>
        <Title title={'Sincronização'} />
        <Body>
          <ButtonHttpRequest
            method='get'
            url={`${ELASTIC_INSTALLER_SERVICE}/server/${projectId}/up`}
            texts={{
              normal: 'Instalar Elasticsearch',
              sending: 'Instalando Elasticsearch...'
            }}
          />

          <ButtonHttpRequest
            method='get'
            url={`${ELASTIC_INSTALLER_SERVICE}/server/${projectId}/down`}
            texts={{
              normal: 'Desinstalar Elasticsearch',
              sending: 'Desinstalando Elasticsearch...'
            }}
          />

          <ButtonHttpRequest
            method='get'
            url={`${WRITER_SERVICE}/compile-steps/${projectId}`}
            texts={{
              normal: 'Sincronizar etapas',
              sending: 'Sincronizando etapas...'
            }}
          />

          <ButtonHttpRequest
            method='get'
            url={`${WRITER_SERVICE}/compile-options/${projectId}`}
            texts={{
              normal: 'Sincronizar campos personalizados',
              sending: 'Sincronizando campos personalizados...'
            }}
          />            

          <ButtonHttpRequest
            method='get'
            url={`${ELASTIC_INSTALLER_SERVICE}/server/${projectId}/sync`}
            texts={{
              normal: 'Sincronizar campos no Elasticsearch',
              sending: 'Sincronizando campos no Elasticsearch...'
            }}
          />

          <ButtonHttpRequest
            method='get'
            url={`${DIGEST_SERVICE}/capture?projectId=${projectId}`}
            texts={{
              normal: 'Forçar digest',
              sending: 'Forçando digest...'
            }}
          />
        </Body>
      </Container>
    </Layout>
  )
}

export default SyncProjectPage;