import React, { useEffect } from 'react';
import { Provider as StoreProvider } from 'react-redux';

// Services
import store from './shared/store';
import { Hub } from 'aws-amplify';
import './shared/bugsnag';
import './shared/browser-storage';
import './shared/credential-manager';
import './shared/session';
import './shared/authentication';

// Components
import AppBootstrap from './Bootstrap';
import ErrorBoundary from './components/ErrorBoundary';

// Styles
import './App.css';

// Development mode only
if (process.env.REACT_APP_ENV !== 'production') {
  window.store = store;
  window.hub = Hub;
}

function App () {
  useEffect(() => {
    Hub.dispatch('initialize', {});
  }, []);

  return (
    <ErrorBoundary>
      <React.Suspense fallback={<span>Carregando...</span>}>
        <StoreProvider store={store}>
          <div style={{ flexGrow: 1 }}>
            <AppBootstrap />
          </div>
        </StoreProvider>
      </React.Suspense>
    </ErrorBoundary>
  );
}

export default App;