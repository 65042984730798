import React from 'react';
import { useParams } from 'react-router-dom';
import ProjectContext from '../../contexts/Project'

const ProjectBootstrap = ({ children }) => {
  const { projectId } = useParams();
  return (
    <ProjectContext.Provider value={projectId}>
      {children}
    </ProjectContext.Provider>
  )
}

export default ProjectBootstrap;
//export default withRouter(withProject(ProjectLoader));