import { Hub } from 'aws-amplify';

const session = {
  accessToken: null,
  idToken: null,
  profile: undefined,
  setAccessToken: (jwtToken) => {
    session.accessToken = jwtToken;

    Hub.dispatch('session', {
      event: 'credential_updated',
      param: 'access_token',
      value: jwtToken
    });
  },
  setIdToken: (jwtToken) => {
    session.idToken = jwtToken;

    Hub.dispatch('session', {
      event: 'credential_updated',
      param: 'id_token',
      value: jwtToken
    });
  },
  setProfile: (userProfile) => {
    session.profile = { ...userProfile };

    Hub.dispatch('session', {
      event: 'credential_updated',
      param: 'profile',
      value: session.profile
    });
  }
};

if (process.env.NODE_ENV === 'development') {
  window.session = session;
}

export default session;