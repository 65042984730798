import axios from 'axios';
import { useState, useEffect } from 'react';

import authentication from '../shared/authentication';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api.esl.teewe.com.br';

const fetchProject = async (projectId) => {
  var idToken = await authentication.asyncGetIdToken();

  const requestParams = {
    url: `${API_ENDPOINT}/graphql/user`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    json: true,
    data: {
      query: `
        query getUserProjects {
          getProjects {
            error {
              type
            }
            data {
              count
              edges {
                permission
                project {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables: {}
    }
  };

  const response = await axios.request(requestParams);
  const { getProjects } = response.data.data;

  if (getProjects.error) {
    throw new Error(getProjects.error);
  }

  var matches = getProjects.data.edges
    .filter((item) => item.project.id === projectId)
    .map((item) => ({ id: item.project.id, name: item.project.name, permission: item.permission }));

  if (matches.length > 0) {
    return matches[0];
  }

  return null;
}

const useProjectInfo = (userId, projectId) => {
  // eslint-disable-next-line
  const [ loading, setLoading ] = useState(true);
  const [ state, setState ] = useState(undefined);
  // eslint-disable-next-line
  const [ error, setError ] = useState(undefined);
  const [ loadedUserId, setLoadedUserId ] = useState(undefined);
  const [ loadedProjectId, setLoadedProjectId ] = useState(undefined);

  useEffect(() => {
    var unsubscribe = false;

    if ((userId !== loadedUserId) || (projectId !== loadedProjectId)) {
      setLoading(true);

      fetchProject(projectId)
        .then((projectAttributes) => {
          if (!unsubscribe) {
            setError(undefined);
            setState(projectAttributes);
            setLoadedUserId(userId);
            setLoadedProjectId(projectId);
            setLoading(false);
          }
        })
        .catch((err) => {
          if (!unsubscribe) {
            setError(err);
          }          
        });
    }

    return () => {
      unsubscribe = true;
    }
  
  // eslint-disable-next-line
  }, [ userId, projectId ]);

  return state;
}

export default useProjectInfo;