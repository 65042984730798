import React from 'react';
import { withRouter } from 'react-router-dom';

import {
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText
}  from '@material-ui/core';

class MenuItemLink extends React.Component {

  getIcon = () => {
    return this.props.icon ? this.props.icon : 'inbox';
  }

  getText = () => {
    return this.props.text ? this.props.text : 'Sem título';
  }

  getHref = () => {
    return this.props.href ? this.props.href : '/';
  }

  renderIcon = () => {
    if (!this.props.icon) {
      return null;
    }

    return (
      <ListItemIcon>
        <Icon color="secondary">{this.getIcon()}</Icon>
      </ListItemIcon>
    )
  }

  handleClick = (e) => {
    if (this.props.onClick) {
      return this.props.onClick(e);
    }

    if (this.props.history) {
      this.props.history.push(this.getHref());
      return false;
    }
  }

  render = () => {
    return (
      <ListItem button onClick={this.handleClick}>
        {this.renderIcon()}
        <ListItemText primary={this.getText()} />
      </ListItem>
    )
  }
}

export default withRouter(MenuItemLink);