/**
 * Translate permission code
 * @param {String} permission 
 */
function translatePermission (permission) {
  var translations = {
    READ: 'Leitura',
    WRITE: 'Leitura & Escrita',
    ADMIN: 'Gerenciamento',
    OWNER: 'Proprietário'
  }

  if (translations[permission]) {
    return translations[permission];
  }

  return '???';
}

export default translatePermission;