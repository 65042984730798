import { useState, useEffect } from 'react';
//import { Hub } from 'aws-amplify';

import useCurrentUserId from './useCurrentUserId';
import authentication from '../shared/authentication';

const useCurrentUser = () => {
  const currentUserId = useCurrentUserId();
  const [ currentUser, setCurrentUser ] = useState(undefined);

  useEffect(() => {
    var unsubscribe = false;

    if (currentUserId) {
      authentication.asyncGetUserAttributes()
        .then((userAttributes) => {
          if (!unsubscribe) {
            setCurrentUser(userAttributes);
          }
        })
        .catch((err) => {
          if (err) {
            setCurrentUser(undefined);
          }
        })
    }

    /*
    if (session && session.profile) {
      setCurrentUser(session.profile);
    }

    var handler = ({ payload }) => {
      if (payload.event === 'credential_updated' && payload.param === 'profile') {
        setCurrentUser(session.profile);
      }
    }

    Hub.listen('session', handler);
    */

    return () => {
      unsubscribe = true;
      //Hub.remove('session', handler);
    }
  }, [ currentUserId ]);

  return currentUser;
}

export default useCurrentUser;